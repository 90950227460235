import { Injectable } from '@angular/core';
import { HubConnection, HubConnectionBuilder } from '@microsoft/signalr';
import { combineLatest, from, Observable, Subject } from 'rxjs';
import { filter, map, switchMap, take, tap } from 'rxjs/operators';
import { CalculationContext, CalculationProgress } from '../../app/editor/calculation-context.model';
import { SettingsService } from '../settings/settings.service';
import { AuthenticationService } from '../auth/authentication.service';
import { ModelBase } from '../model/model.model';
import { WindowRef } from '../util/window-ref';
import { ModelHashService } from '../model/model-hash.service';
import { LockTokenStorageService } from '../locking/lock-token-storage.service';
import { LockTokenRevocationService } from '../locking/lock-token-revocation.service';
import { loadAppConfig } from '../util/util';

interface CalculateResponse {
    statusCode: string;
    error: string | null;
    calculationId: string;
    hash: string;
    calculateOptiKit: boolean;
}

@Injectable({ providedIn: 'root' })
export class CalculationService {
    public appConfig = loadAppConfig();

    private readonly _connection = this._makeHubConnection();
    private readonly _receiveCalculationProgress = new Subject<CalculationProgress>();
    public calculateOptiKit: boolean;

    constructor(
        private readonly _authService: AuthenticationService,
        private readonly _settingsService: SettingsService,
        private readonly _windowRef: WindowRef,
        private readonly _lockTokenRevocationService: LockTokenRevocationService,
        private readonly _lockTokenStorageService: LockTokenStorageService,
        private readonly _modelHashService: ModelHashService,
    ) {}

    public async start(): Promise<void> {
        // TODO react to access token refresh ?!
        try {
            await this._connection.start();
        } catch (err) {
            const reconnect = err.statusCode !== 0;
            if (reconnect) {
                this._reconnect();
            }
        }
    }

    public calculate(model: ModelBase, calculateOptiKit: boolean): Observable<CalculationContext> {
        const { tenantId, groupId, id } = model;
        this.calculateOptiKit = calculateOptiKit;
        return combineLatest([this._settingsService.currentLanguage$, this._settingsService.currentUnitSet$]).pipe(
            take(1),
            map(([language, unitSet]) =>
                this._connection.invoke<CalculateResponse>(
                    'Calculate',
                    language,
                    unitSet,
                    tenantId!,
                    groupId!,
                    id,
                    this._lockTokenStorageService.get(model.id)?.id,
                    this._modelHashService.hash,
                    calculateOptiKit,
                ),
            ),
            switchMap((hupMethod) => from(hupMethod)),
            tap(({ statusCode, error }) => {
                if (statusCode === 'Conflict') {
                    this._modelHashService.reload();
                    return;
                }

                if (statusCode === 'Gone') {
                    this._lockTokenRevocationService.revoke();
                    return;
                }

                if (statusCode !== 'Accepted') {
                    throw new Error(error === null ? 'Unknown push error.' : error);
                }
            }),
            filter(({ calculationId }) => !!calculationId),
            map(({ calculationId, hash }) => {
                this._modelHashService.hash = hash;
                return new CalculationContext(this._modelHashService, calculationId, this._receiveCalculationProgress);
            }),
        );
    }

    // TODO: Disable calc button in dashboard when no WS connection is present
    // TODO: Testability for HubConnection
    private _makeHubConnection(): HubConnection {
        const hubConnection = new HubConnectionBuilder()
            .withUrl(`${this.appConfig.baseUrl}push/${this.appConfig.apiVersion}models`, {
                accessTokenFactory: () => (this._authService.hasValidAccessToken() ? this._authService.accessToken : ''),
            })
            .withAutomaticReconnect()
            .build();

        hubConnection.on('receiveCalculationProgress', (progress) => this._receiveCalculationProgress.next(progress));
        hubConnection.onclose(() => this._reconnect());

        return hubConnection;
    }

    private _reconnect(): void {
        this._windowRef.nativeWindow.setTimeout(() => this.start(), 5000);
    }
}
