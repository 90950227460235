import { Directive, ElementRef, Inject, Input, LOCALE_ID, OnDestroy, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { fromEvent, isObservable, Subject } from 'rxjs';
import { map, startWith, takeUntil } from 'rxjs/operators';
import { precisionFormatter, roundNumberWithPrecision, stripAndParse } from '../../../modules/util/util';

@Directive({
    // eslint-disable-next-line @angular-eslint/directive-selector
    selector: 'input[bxPrecisionInput]',
})
export class PrecisionInputDirective implements OnInit, OnDestroy {
    @Input() bxPrecisionInput: FormControl;
    @Input() precision?: number;

    private _stop$ = new Subject();

    constructor(
        @Inject(LOCALE_ID) private readonly _locale: string,
        private readonly _elementRef: ElementRef<HTMLInputElement>,
    ) {}

    public ngOnInit(): void {
        if (!this.bxPrecisionInput) {
            throw new Error('bxPrecisionInput: Expecting a FormControl');
        }

        const format = precisionFormatter(this._locale, this.precision);
        const round = roundNumberWithPrecision(this.precision);
        const roundWithStrip = this.precision !== undefined ? (v: string) => round(stripAndParse(v)) : (v: any) => v;
        const inputElement = this._elementRef.nativeElement;

        // React to changing the input manually. After set value inside angular
        fromEvent<any>(inputElement, 'change')
            .pipe(
                takeUntil(this._stop$),
                map((event) => event.target.value),
            )
            .subscribe((value) => {
                this.bxPrecisionInput.setValue(roundWithStrip(value));
            });

        // On every change, format the value. After set data to the element
        this.bxPrecisionInput.valueChanges.pipe(takeUntil(this._stop$), startWith(this.bxPrecisionInput.value)).subscribe((view) => {
            if (isObservable(view)) {
                view.subscribe((v: string | number) => (inputElement.value = format(v)));
            } else {
                inputElement.value = format(view);
            }
        });

        // React to enabling and disabling of the FormControl
        this.bxPrecisionInput.statusChanges
            .pipe(
                takeUntil(this._stop$),
                map((status) => status === 'DISABLED'),
                startWith(this.bxPrecisionInput.disabled),
            )
            .subscribe((isDisabled) => {
                inputElement.disabled = isDisabled;
            });
    }

    public ngOnDestroy(): void {
        this._stop$.next();
        this._stop$.complete();
    }
}
