<form [formGroup]="form">
    <bx-form-field>
        <label>{{ 'SHARED.SETTINGS.DEFAULT_VIEW.TITLE' | translate }}</label>
        <bx-combo-box
            *ngIf="defaultView$ | async as defaultView"
            formControlName="view"
            [options]="defaultView"
            (filter)="save()"
            [placeholder]="'SHARED.SETTINGS.DEFAULT_VIEW.DEFAULT_VIEW_PLACEHOLDER' | translate"
        ></bx-combo-box>
    </bx-form-field>
</form>
