import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AboutComponent } from '../about/about.component';
import { AuthenticationService } from '../auth/authentication.service';
import { BookmarkService } from '../bookmark/bookmark.service';
import { DrawerComponent } from '../drawer/drawer.component';
import { ImprintComponent } from '../markdown-dialogs/imprint.component';
import { ModalService } from '../modal/services/modal.service';
import { ModelService } from '../model/model.service';
import { Roles } from '../role/role.model';
import { ShareDialogComponent } from '../share/share-dialog.component';
import { InitializationStatusService } from '../initialization/initialization-status.service';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
    selector: 'bx-main-navigation',
    templateUrl: './navigation.component.html',
    styleUrls: ['./navigation.component.scss'],
})
export class NavigationComponent implements OnInit, OnDestroy {
    @Input() drawer: DrawerComponent;

    public readonly Roles = Roles;
    public readonly bookmarks$ = this._bookmarkService.bookmarks$;
    public readonly model$ = this._modelService.model$;
    public isInitializedSuccessfully = false;

    private readonly _destroy$ = new Subject<void>();

    constructor(
        private readonly _router: Router,
        private readonly _modelService: ModelService,
        private readonly _modalService: ModalService,
        private readonly _authService: AuthenticationService,
        private readonly _bookmarkService: BookmarkService,
        private readonly _initializationStatusService: InitializationStatusService,
    ) {}

    public ngOnInit(): void {
        this._initializationStatusService.initializationStatus$.pipe(takeUntil(this._destroy$)).subscribe((status) => {
            this.isInitializedSuccessfully = status;
        });
    }

    public ngOnDestroy(): void {
        this._destroy$.next();
        this._destroy$.complete();
    }

    public navigateTo(...commands: string[]): Promise<boolean> {
        this._toggleDrawer();
        return this._router.navigate([...commands]);
    }

    public logout(): void {
        this._authService.logout();
    }

    public closeModel(): Promise<boolean> {
        this._toggleDrawer();
        return this._router.navigate(['/'], { state: { checkInModel: true } });
    }

    public shareModel(): void {
        this._toggleDrawer();
        this._modalService.showContentDialog(ShareDialogComponent, 'SHARED.SHARE_DIALOG.TITLE');
    }

    public showAbout(): void {
        this._toggleDrawer();
        this._modalService.showContentDialog(AboutComponent, 'SHARED.NAVIGATION.ABOUT');
    }

    public showImprint(): void {
        this._toggleDrawer();
        this._modalService.showContentDialog(ImprintComponent, 'SHARED.NAVIGATION.IMPRINT');
    }

    private _toggleDrawer(): void {
        if (this.drawer) {
            this.drawer.toggle();
        }
    }
}
