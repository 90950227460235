import { Directive, Input, OnDestroy, OnInit, TemplateRef, ViewContainerRef } from '@angular/core';
import { ReplaySubject, Subscription } from 'rxjs';
import { RoleService } from '../role.service';
import { switchMap } from 'rxjs/operators';

@Directive({
    selector: '[bxIfRoles]',
})
export class IfRolesDirective implements OnInit, OnDestroy {
    private readonly _rolesSubject = new ReplaySubject<string[]>(1);
    private _subscription = Subscription.EMPTY;
    private _hasView = false;

    @Input()
    public set bxIfRoles(value: string[]) {
        this._rolesSubject.next(value);
    }

    constructor(
        private readonly _templateRef: TemplateRef<any>,
        private readonly _viewContainer: ViewContainerRef,
        private readonly _roleService: RoleService,
    ) {}

    public ngOnInit(): void {
        this._subscription = this._rolesSubject
            .pipe(switchMap((roles) => this._roleService.userHasRoles(roles)))
            .subscribe((userHasRoles) => {
                if (userHasRoles && !this._hasView) {
                    this._viewContainer.createEmbeddedView(this._templateRef);
                    this._hasView = true;
                } else if (!userHasRoles && this._hasView) {
                    this._viewContainer.clear();
                    this._hasView = false;
                }
            });
    }

    public ngOnDestroy(): void {
        this._subscription.unsubscribe();
    }
}
