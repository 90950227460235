<form [formGroup]="form">
    <bx-form-field>
        <label>{{ 'SHARED.SETTINGS.APP.LANGUAGE_LABEL' | translate }}</label>
        <bx-combo-box
            formControlName="language"
            [options]="languages"
            (filter)="save()"
            [placeholder]="'SHARED.SETTINGS.APP.LANGUAGE_PLACEHOLDER' | translate"
        ></bx-combo-box>
    </bx-form-field>

    <bx-form-field>
        <label>{{ 'SHARED.SETTINGS.APP.UNIT_SET_LABEL' | translate }}</label>
        <bx-combo-box
            formControlName="unitSet"
            [options]="unitSets"
            (filter)="save()"
            [placeholder]="'SHARED.SETTINGS.APP.UNIT_SET_PLACEHOLDER' | translate"
        ></bx-combo-box>
    </bx-form-field>

    <bx-form-field>
        <label>{{ 'SHARED.SETTINGS.APP.LOCALE' | translate }}</label>
        <bx-combo-box
            formControlName="locale"
            [options]="locales$ | async"
            (filter)="save()"
            [placeholder]="'SHARED.SETTINGS.APP.LOCALE_PLACEHOLDER' | translate"
        ></bx-combo-box>
    </bx-form-field>

    <div>
        <label>{{ 'SHARED.SETTINGS.APP.LOCALE_PREVIEW' | translate }}</label>
        <pre class="preview"
            >{{ now | date: 'short':undefined:form.value.locale || undefined }}
{{ 1000000.1234 | number: undefined:form.value.locale || undefined }}
        </pre>
    </div>

    <div class="warn" *ngIf="localeMismatch">
        <div>{{ 'SHARED.SETTINGS.APP.LOCALE_MISMATCH_WARNING' | translate }}</div>
        <button (click)="reload()">{{ 'GLOBALS.RELOAD' | translate }}</button>
    </div>
</form>
