<form [formGroup]="form">
    <bx-form-field>
        <bx-checkbox
            formControlName="rotateAroundSelection"
            (change)="save()"
            [label]="'SHARED.SETTINGS.SCENE3D.ROTATE_AROUND_SELECTION' | translate"
        ></bx-checkbox>
    </bx-form-field>
    <bx-form-field>
        <bx-checkbox
            formControlName="zoomToMousePosition"
            (change)="save()"
            [label]="'SHARED.SETTINGS.SCENE3D.ZOOM_TO_MOUSE_POSITION' | translate"
        ></bx-checkbox>
    </bx-form-field>
    <bx-form-field>
        <label>{{ 'SHARED.SETTINGS.SCENE3D.CLIPPING_PLANE' | translate }}</label>
        <bx-combo-box
            *ngIf="clippingPlanesTypes$ | async as clippingPlanesTypes"
            formControlName="clippingPlane"
            [options]="clippingPlanesTypes | sortBy: 'caption'"
            (filter)="save()"
            [placeholder]="'SHARED.SETTINGS.SCENE3D.CLIPPING_PLANE_PLACEHOLDER' | translate"
        ></bx-combo-box>
    </bx-form-field>
    <bx-form-field>
        <bx-checkbox
            formControlName="flipClippingPlane"
            (change)="save()"
            [label]="'SHARED.SETTINGS.SCENE3D.FLIP_CLIPPING_PLANE' | translate"
        ></bx-checkbox>
    </bx-form-field>
    <bx-form-field>
        <bx-checkbox formControlName="textured" (change)="save()" [label]="'SHARED.SETTINGS.SCENE3D.TEXTURED' | translate"></bx-checkbox>
    </bx-form-field>

    <bx-form-field>
        <label>{{ 'SHARED.SETTINGS.SCENE3D.CAMERA_TYPE' | translate }}</label>
        <bx-combo-box
            *ngIf="cameraTypes$ | async as cameraTypes"
            formControlName="cameraType"
            [options]="cameraTypes | sortBy: 'caption'"
            (filter)="save()"
            [placeholder]="'SHARED.SETTINGS.SCENE3D.CAMERA_TYPE_PLACEHOLDER' | translate"
        ></bx-combo-box>
    </bx-form-field>
    <bx-default-view-settings></bx-default-view-settings>
</form>
