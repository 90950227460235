<div [class.cb-group]="!asToggle" [class.toggle]="asToggle">
    <input type="checkbox" (change)="onChange($event)" (blur)="onBlur()" [disabled]="disabledFlag$ | async" [checked]="checked" #checkbox />
    <span class="handle" (click)="checkbox.click()">
        <ng-container *ngIf="!asToggle">
            <i class="material-icons" *ngIf="checked">check_box</i>
            <i class="material-icons" *ngIf="!checked">check_box_outline_blank</i>
        </ng-container>
    </span>
    <label
        class="value"
        [ngClass]="{
            disabled: isDisabled,
        }"
    >
        <span *ngIf="asToggle"> {{ checked ? caption : offCaption }} </span>
        <span *ngIf="!asToggle && label"> {{ label }} </span>
    </label>
</div>
