import { ComboBoxOption } from '../../form-control/combo-box/combo-box-option.model';
import { TranslateService } from '@ngx-translate/core';
import { BaseExporter } from './base.exporter';

export class ExportComboBoxOptionModel implements ComboBoxOption {
    id: string;
    caption: string;

    constructor(
        public readonly exporter: BaseExporter,
        public readonly translateService: TranslateService,
    ) {
        this.id = exporter.getExporterName();
        this.caption = translateService.instant(`SHARED.MODEL.EXPORT.FORMATS.${this.id.toUpperCase()}`);
    }
}
