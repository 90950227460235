import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { HttpStatusCode } from '../error-handling';
import { ModelHashService } from './model-hash.service';
import { loadAppConfig } from '../util/util';

export const HASH_HEADER = 'x-bearinx-hash';
@Injectable()
export class ModelHashInterceptor implements HttpInterceptor {
    public appConfig = loadAppConfig();
    private readonly _baseUrl = `${this.appConfig.baseUrl}${this.appConfig.apiVersion}models/`;
    private readonly _ignoredHeaders = ['GET', 'HEAD', 'OPTIONS', 'TRACE'];
    private static readonly _PROJECT_DUPLICATE = 'target environment';

    constructor(private readonly _modelHashService: ModelHashService) {}

    public intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        if (!req.url.startsWith(this._baseUrl)) {
            return next.handle(req);
        }

        let request = req;
        if (this._ignoredHeaders.indexOf(req.method) < 0 && this._modelHashService.hash) {
            request = request.clone({ setHeaders: { [HASH_HEADER]: this._modelHashService.hash } });
        }

        const navigator = window.navigator as any;

        return next.handle(request).pipe(
            catchError((err: HttpErrorResponse) => {
                if (err.status === HttpStatusCode.Conflict && !err.error.includes(ModelHashInterceptor._PROJECT_DUPLICATE)) {
                    if (navigator.connection.effectiveType === '2g') {
                        this._modelHashService.showSlowInternetMessage();
                    } else {
                        this._modelHashService.reload();
                    }
                }
                return throwError(err);
            }),
            tap((evt) => {
                if (evt instanceof HttpResponse) {
                    this._modelHashService.hash = evt.headers.get(HASH_HEADER) || this._modelHashService.hash;
                }
            }),
        );
    }
}
