import { Component, OnDestroy, ViewChild, AfterViewInit } from '@angular/core';
import { Router } from '@angular/router';
import { of, Observable, Subject } from 'rxjs';
import { map, switchMap, tap, takeUntil } from 'rxjs/operators';
import { Project } from '../../../app/project/project.model';
import { ProjectService } from '../../../app/project/project.service';
import { ImportState } from '../import-state.model';
import { ModelMetadata } from '../metadata-form/model-metadata.model';
import { ModelApiService } from '../model-api.service';
import { ModelService } from '../model.service';
import { AppBarService } from 'src/app/bearinx/app-container/app-bar.service';
import { ModelMetadataFormComponent } from '../metadata-form/model-metadata-form.component';

@Component({
    selector: 'bx-metadata-editor',
    templateUrl: 'metadata-editor.component.html',
    styleUrls: ['./metadata-editor.component.scss'],
})
export class MetadataEditorComponent implements OnDestroy, AfterViewInit {
    private readonly _destroy$ = new Subject<void>();
    @ViewChild('model_metadata_form') modelMetadataForm: ModelMetadataFormComponent;

    constructor(
        public readonly modelService: ModelService,
        private _router: Router,
        private readonly _modelApiService: ModelApiService,
        private readonly _projectService: ProjectService,
        private readonly _appBarService: AppBarService,
    ) {}

    public ngAfterViewInit() {
        this._appBarService.readOnlyActive$.pipe(takeUntil(this._destroy$)).subscribe((readOnlyActive) => {
            this.modelMetadataForm.toggleDisableForm(readOnlyActive);
        });
    }

    public ngOnDestroy(): void {
        this._destroy$.next();
        this._destroy$.complete();
    }

    public onConfirm(metadata: ModelMetadata): void {
        const metadataCopy = Object.assign({}, metadata, { state: ImportState.Imported });
        const id = this.modelService.model!.id;

        this._getProjectId(metadata)
            .pipe(
                tap((projectId) => {
                    metadataCopy.projectId = projectId;
                    metadataCopy.project = { ...metadata.project, id: projectId!, groupId: this.modelService.model!.groupId } as Project;
                }),
                tap(() => delete metadata.project),
                map((projectId) => (metadata.projectId = projectId)),
                switchMap(() => this._modelApiService.update(id, metadata)),
                takeUntil(this._destroy$),
            )
            .subscribe(() => this._saveSuccessful(id, metadataCopy));
    }

    private _getProjectId(metadata: ModelMetadata): Observable<string | null> {
        if (!!metadata.project && !metadata.project.id) {
            return this._projectService.create(metadata.project);
        }
        return of(!!metadata.project ? metadata.project.id! : null);
    }

    private _saveSuccessful(id: string, metadata: ModelMetadata) {
        Object.assign(this.modelService.model!, metadata);
        this._goEditor(this.modelService.model!.tenantId!, this.modelService.model!.groupId!, id);
    }

    public onCancel(): void {
        const model = this.modelService.model!;
        this._goEditor(model.tenantId!, model.groupId!, model!.id);
    }

    private _goEditor(tenantId: string, groupId: string, id: string): void {
        this._router.navigate(['editor', tenantId, groupId, id, 'edit']);
    }
}
