import { Component, forwardRef, Input } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
    selector: 'bx-tags-input',
    templateUrl: './tagsInput.component.html',
    styleUrls: ['./tagsInput.component.scss'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            // eslint-disable-next-line @typescript-eslint/no-use-before-define
            useExisting: forwardRef(() => TagsInputComponent),
            multi: true,
        },
    ],
})
export class TagsInputComponent implements ControlValueAccessor {
    @Input() public placeholder = '';
    @Input() public maxLength = 100;

    public tags: string[] = [];
    public isDisabled = false;

    private _onChange = (_val: string[]) => void 0;
    private _onTouched = () => void 0;

    public writeValue(value: string[]): void {
        this.tags = value;
    }

    public registerOnChange(fn: any): void {
        this._onChange = fn;
    }

    public registerOnTouched(fn: any): void {
        this._onTouched = fn;
    }

    public setDisabledState(disabled: boolean): void {
        this.isDisabled = disabled;
    }

    public onBlur(): void {
        this._onTouched();
    }

    public onConfirm(inputElement: any): void {
        const tag = inputElement.value;
        if (this.tags.indexOf(tag) < 0 && tag.length >= 3) {
            this.tags = this.tags.concat(tag);
            this._onChange(this.tags);
        }
        inputElement.value = '';
    }

    public onRemoveTag(tag: string): void {
        if (this.isDisabled) {
            return;
        }
        const count = this.tags.length;
        this.tags = this.tags.filter((t) => t !== tag);
        if (count > this.tags.length) {
            this._onChange(this.tags);
        }
    }
}
