import { BehaviorSubject } from 'rxjs';
import { Component, EventEmitter, forwardRef, Input, Output } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
    selector: 'bx-checkbox',
    templateUrl: './checkbox.component.html',
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            // eslint-disable-next-line @typescript-eslint/no-use-before-define
            useExisting: forwardRef(() => CheckboxComponent),
            multi: true,
        },
    ],
    styleUrls: ['./checkbox.component.scss'],
})
export class CheckboxComponent implements ControlValueAccessor {
    @Input() public label = '';
    @Input() public caption = 'On';
    @Input() public offCaption = 'Off';
    @Input() public asToggle = false;

    @Input() public onValue: any = true;
    @Input() public offValue: any = false;
    @Input() public isDisabled = false;

    @Output() public toggle = new EventEmitter<any>();

    public checked = false;

    private _onChange = (_val: any) => void 0;
    private _onTouched = () => void 0;

    private readonly _disabledFlag$ = new BehaviorSubject<boolean>(this.isDisabled);
    public readonly disabledFlag$ = this._disabledFlag$.asObservable();

    public writeValue(value: boolean): void {
        this.checked = value === this.onValue;
    }

    public registerOnChange(fn: any): void {
        this._onChange = fn;
    }

    public registerOnTouched(fn: any): void {
        this._onTouched = fn;
    }

    public setDisabledState(disabled: boolean): void {
        this.isDisabled = disabled;
        this._disabledFlag$.next(this.isDisabled);
    }

    public onChange(event: any): void {
        this.checked = event.target.checked;
        this._onChange(this.checked ? this.onValue : this.offValue);
        this.toggle.emit(this.checked ? this.onValue : this.offValue);
    }

    public onBlur(): void {
        this._onTouched();
    }
}
