import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { HttpStatusCode } from '../error-handling';
import { ModelService } from '../model/model.service';
import { LockTokenRevocationService } from './lock-token-revocation.service';
import { LockTokenStorageService } from './lock-token-storage.service';
import { loadAppConfig } from '../util/util';

@Injectable()
export class LockTokenInterceptor implements HttpInterceptor {
    constructor(
        private readonly _lockTokenStorageService: LockTokenStorageService,
        private readonly _lockTokenRevocationService: LockTokenRevocationService,
        private readonly _modelService: ModelService,
    ) {}

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        const appConfig = loadAppConfig();
        if (!req.url.startsWith(appConfig.baseUrl) || !this._modelService.model) {
            return next.handle(req);
        }

        const lockToken = this._lockTokenStorageService.get(this._modelService.model.id);
        if (!lockToken) {
            return next.handle(req);
        }

        return next.handle(req.clone({ setHeaders: { 'x-bearinx-locktoken': lockToken.id } })).pipe(
            catchError((err: HttpErrorResponse) => {
                if (err.status === HttpStatusCode.Gone) {
                    // Lock token not valid anymore, probably overridden.
                    this._lockTokenRevocationService.revoke();
                    (err as unknown as { [key: string]: boolean }).suppressMessage = true;
                }
                return throwError(err);
            }),
        );
    }
}
