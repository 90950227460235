import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
    selector: 'bx-filter-box',
    templateUrl: 'filter-box.component.html',
    styleUrls: ['filter-box.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FilterBoxComponent {
    @Input()
    public placeholder = '';

    @Output()
    public filter = new EventEmitter<string | null>();

    public updateFilter(value: string): void {
        this.filter.emit(value || null);
    }
}
