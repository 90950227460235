import { ActivatedRouteSnapshot, CanActivateChildFn, CanActivateFn, RouterStateSnapshot } from '@angular/router';
import { of } from 'rxjs';
import { RoleService } from './role.service';
import { inject } from '@angular/core';
import { catchError, map } from 'rxjs/operators';

export namespace RoleGuard {
    export const canActivate: CanActivateFn = (route: ActivatedRouteSnapshot, _state: RouterStateSnapshot) => {
        const roleService = inject(RoleService);
        const roles = { requiredRoles: [], ...route.data }.requiredRoles;
        if (!Array.isArray(roles)) {
            throw new Error('No requiredRoles found on route data.');
        }

        return roleService.userHasRoles(roles).pipe(
            map((userHasRoles) => userHasRoles),
            catchError(() => of(false)),
        );
    };

    export const canActivateChild: CanActivateChildFn = (route: ActivatedRouteSnapshot, _state: RouterStateSnapshot) =>
        canActivate(route, _state);
}
