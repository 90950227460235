import { Injectable } from '@angular/core';
import { Observable, Subject, of } from 'rxjs';
import { Model } from '../model.model';
import { ModelObject } from '../model-object.model';
import { ExportConstants } from './export.constants';
import { DataProvider } from './data.provider';
import { hasProperty } from './has-property';
import { BearinxGltfTextExporterService } from '@webfoundation/viewer';
import { GltfTextExportInterface } from './data-types/gltfText.export.interface';

@Injectable()
export class GltfTextDataprovider implements DataProvider {
    public readonly inputFormats = [ExportConstants.vg2Json];

    public readonly outputFormat = ExportConstants.gltfText;

    constructor(private readonly _bearinxGltfTextExporterService: BearinxGltfTextExporterService) {}

    public convert(input: any): Observable<any> {
        return new Observable<GltfTextExportInterface>((observer) => {
            const model = <Model<ModelObject>>input;
            if (model) {
                const vg2Json = ['id', 'name', 'project', 'method'];
                if (vg2Json.every((attr) => hasProperty(model, attr))) {
                    const finished$ = new Subject<void>();
                    const modelId = model.id;

                    this._bearinxGltfTextExporterService.export(model)!.subscribe((vg2Data) => {
                        finished$.next();
                        finished$.complete();

                        observer.next({
                            modelId: modelId,
                            modelName: model.name,
                            data: vg2Data,
                        });
                        observer.complete();
                    });
                }
            } else {
                observer.error();
                observer.complete();
            }
        });
    }
}
