import { ChangeDetectionStrategy, Component } from '@angular/core';
import { Observable } from 'rxjs';
import { Version } from '../version/version.model';
import { VersionService } from '../version/version.service';
import { AboutService } from './about.service';

@Component({
    styleUrls: ['about.component.scss'],
    templateUrl: 'about.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AboutComponent {
    readonly thirdPartyLicenses$: Observable<string> = this._aboutService.loadThirdPartyLicenses();
    readonly versions$: Observable<Version[]> = this._versionService.versions$;
    readonly columns = ['name', 'version'];
    public isInitializedSuccessfully = false;

    constructor(
        private readonly _versionService: VersionService,
        private readonly _aboutService: AboutService,
    ) {}
}
