import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'expand',
})
export class ExpandPipe implements PipeTransform {
    public transform(length: number): number[] {
        return new Array(length).fill(0);
    }
}
