import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { defer, Observable, of, Subject, throwError } from 'rxjs';
import { catchError, map, retryWhen, share, switchMap, takeUntil } from 'rxjs/operators';
import { PromptResult } from '../../prompt/prompt.model';
import { SpinnerService } from '../../spinner/spinner.service';
import { ErrorMessagesService } from '../error-messages/error-messages.service';
import { LoggingService } from '../logging.service';
import { HttpStatusCode } from './http-status-code.model';
import { loadAppConfig } from 'src/modules/util/util';
import { ErrorPromptContent } from '../prompt-error-message.model';
import { TranslateService } from '@ngx-translate/core';

const OFFLINE_STATUS = 0;
const retryCodeList = [OFFLINE_STATUS, HttpStatusCode.ServiceUnavailable, HttpStatusCode.GatewayTimeout];

function confirmRetry<T>(retry$: Observable<boolean>): (observable: Observable<T>) => Observable<T> {
    return retryWhen((errors) =>
        errors.pipe(
            switchMap((error) => {
                if (retryCodeList.includes(error.status)) {
                    return retry$.pipe(switchMap((retry) => (retry ? of(1) : throwError(error))));
                }
                return throwError(error);
            }),
        ),
    );
}

@Injectable()
export class HttpErrorInterceptor implements HttpInterceptor {
    private readonly _retry$ = defer(() => {
        this._spinnerService.removeAllSpinners();
        this._errorMessagesService.hideAllMessages();

        return this._errorMessagesService.displayServerUnavailableErrorMessage().pipe(map((result) => result === PromptResult.Confirm));
    }).pipe(share());

    private readonly _destroy$ = new Subject<void>();

    constructor(
        private _loggingService: LoggingService,
        private _errorMessagesService: ErrorMessagesService,
        private _spinnerService: SpinnerService,
        private _translateService: TranslateService,
    ) {}

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        const appConfig = loadAppConfig();
        // Allow using the app with offline settings (cannot inject settings service)
        if (request.url === appConfig.settingsUrl) {
            return next.handle(request);
        }

        // Let initializer requests pass through (TODO use HttpContext in ng13)
        if (request.url.startsWith(appConfig.oauth.issuer!) || request.url.includes('DataModel/model')) {
            return next.handle(request);
        }

        return next.handle(request).pipe(
            confirmRetry(this._retry$),
            catchError((error) => {
                if (error instanceof HttpErrorResponse) {
                    this._loggingService.log(`Error Code: ${error.status}\nMessage: ${error.message}`);
                    this._displayError(error, request, next);
                }
                return throwError(error);
            }),
        );
    }

    private _displayError(error: HttpErrorResponse, request: HttpRequest<any>, next: HttpHandler): void {
        this._displayBackendError(error);
        this._displayAccessDeniedError(error, request);
        this._displayTimeoutError(error, request, next);
        this._displayServiceUnavailableError(error, request, next);
    }

    private _displayBackendError(error: HttpErrorResponse): void {
        if (error.status === HttpStatusCode.NotImplemented) {
            this._errorMessagesService.displayGeneralErrorMessage();
        }
    }

    private _displayAccessDeniedError(error: HttpErrorResponse, request: HttpRequest<any>): void {
        if (error.status === HttpStatusCode.Unauthorized) {
            this._showSupportPrompt({ title: 'ACCESS_DENIED.TITLE', message: 'ACCESS_DENIED.MESSAGE' });
        }
        if (error.status === HttpStatusCode.Forbidden && request.headers.get('x-handle-403-internally') !== 'true') {
            this._showSupportPrompt({ title: 'ACCESS_DENIED.TITLE', message: 'ACCESS_DENIED.MESSAGE' });
        }
    }

    private _displayTimeoutError(error: HttpErrorResponse, request: HttpRequest<any>, next: HttpHandler): void {
        if (error.status === HttpStatusCode.RequestTimeout) {
            this._showRetryPromptWithTranslation('REQUEST_TIMEOUT.TITLE', 'REQUEST_TIMEOUT.MESSAGE', request, next);
        }
    }

    private _displayServiceUnavailableError(error: HttpErrorResponse, request: HttpRequest<any>, next: HttpHandler): void {
        if (
            error.status === HttpStatusCode.InternalServerError ||
            error.status === HttpStatusCode.ServiceUnavailable ||
            error.status === HttpStatusCode.GatewayTimeout ||
            error.status === HttpStatusCode.BadGateway
        ) {
            this._showRetryPromptWithTranslation('SERVICE_UNAVAILABLE.TITLE', 'SERVICE_UNAVAILABLE.MESSAGE', request, next);
        }
    }

    private _showRetryPromptWithTranslation(titleKey: string, messageKey: string, request: HttpRequest<any>, next: HttpHandler): void {
        const title = this._translateService.instant(titleKey);
        const message = this._translateService.instant(messageKey);
        this._showRetryPrompt({ title, message }, request, next);
    }

    private _clearUI(): void {
        this._spinnerService.removeAllSpinners();
        this._errorMessagesService.hideAllMessages();
    }

    private _handlePrompt(displayComponentFn: () => Observable<PromptResult>, onConfirm: () => void): void {
        defer(() => {
            this._clearUI();
            return displayComponentFn().pipe(
                takeUntil(this._destroy$),
                map((result) => result === PromptResult.Confirm),
            );
        })
            .pipe(share())
            .subscribe((confirmed) => {
                if (confirmed) {
                    onConfirm();
                } else {
                    this._destroy$.next();
                    this._destroy$.complete();
                }
            });
    }

    private _showSupportPrompt(promptErrorMessage: ErrorPromptContent): void {
        this._handlePrompt(
            () => this._errorMessagesService.displayErrorContactSupportComponent(promptErrorMessage),
            () => {
                // No additional action needed on confirm
            },
        );
    }

    private _showRetryPrompt(promptErrorMessage: ErrorPromptContent, request: HttpRequest<any>, next: HttpHandler): void {
        this._handlePrompt(
            () => this._errorMessagesService.displayRetryComponent(promptErrorMessage),
            () => {
                next.handle(request)
                    .pipe(
                        catchError((error) => {
                            error.suppressMessage = true;
                            if (error instanceof HttpErrorResponse) {
                                this._displayError(error, request, next);
                            }
                            return throwError(error);
                        }),
                    )
                    .subscribe();
            },
        );
    }
}
