import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { combineLatest, defer, Observable, Subject } from 'rxjs';
import { filter, map, startWith, take, takeUntil } from 'rxjs/operators';
import { ComboBoxOption } from '../../form-control/combo-box/combo-box-option.model';
import { Roles } from '../../role/role.model';
import { RoleService } from '../../role/role.service';
import { TenantService } from '../../tenant/tenant.service';
import { ModelApiService } from '../model-api.service';
import { ModelObject } from '../model-object.model';
import { Model } from '../model.model';
import { ModelService } from '../model.service';
import { ProjectService } from '../../../app/project/project.service';
import { SelectProject, Project } from '../../../app/project/project.model';
import { OverlayRef } from '@angular/cdk/overlay';
import { ModalService } from '../../modal/services/modal.service';
import { ProjectSelectionService } from '../../project-selection/project-selection.service';
import { ProjectSelectionComponent } from '../../project-selection/project-selection.component';
import { DialogButtonPosition } from '../../modal/models/dialog.preset';
import { ErrorMessagesService, ErrorsHandledInInterceptor } from '../../../modules/error-handling';
import { AppBarService } from 'src/app/bearinx/app-container/app-bar.service';
import { GroupService } from 'src/modules/group/group.service';
import { HttpErrorResponse } from '@angular/common/http';

interface WritableTenant extends ComboBoxOption {
    groups: ComboBoxOption[];
}

@Component({
    selector: 'bx-model-copy',
    templateUrl: './model-copy.component.html',
    styleUrls: ['./model-copy.component.scss'],
})
export class ModelCopyComponent implements OnInit, OnDestroy {
    public writableTenants$: Observable<WritableTenant[]>;
    public writableGroups$: Observable<ComboBoxOption[]>;
    public formGroup: FormGroup;

    private _destroy$ = new Subject<void>();
    private _projects: Project[] = [];
    private _projectSelectionOverlay: OverlayRef;
    private _selectedProject: Project | undefined | null;

    private initialTenant = this._tenantService.tenant;
    public disableSelectButton = false;

    constructor(
        private readonly _modelApiService: ModelApiService,
        private readonly _formBuilder: FormBuilder,
        private readonly _modelService: ModelService,
        private readonly _router: Router,
        private readonly _roleService: RoleService,
        private readonly _tenantService: TenantService,
        private readonly _groupService: GroupService,
        private readonly _projectService: ProjectService,
        private readonly _modalService: ModalService,
        private readonly _projectSelectionService: ProjectSelectionService,
        private readonly _errorService: ErrorMessagesService,
        private readonly _appBarService: AppBarService,
    ) {
        this._projectSelectionService.currentlySelected.pipe(takeUntil(this._destroy$)).subscribe((selected) => {
            this._selectedProject = selected;
        });
    }

    public ngOnInit(): void {
        this.formGroup = this._formBuilder.group({
            name: ['', Validators.required],
            tenantId: ['', Validators.required],
            groupId: ['', Validators.required],
            projectId: [''],
        });

        this._appBarService.toggleReadOnlyActive(true);

        this._modelService.model$
            .pipe(
                filter((model): model is Model<ModelObject> => !!model),
                take(1),
            )
            .subscribe((model) => {
                this.formGroup.patchValue(model);
                const project = model!.project;
                if (this.initialTenant?.name === 'Demo') {
                    return;
                }
                if (project && project.groupId === model?.groupId) {
                    this._projectService.selectProject(project.id);
                    this.formGroup.patchValue({ projectId: project.id });
                }
            });

        this.writableTenants$ = combineLatest([this._tenantService.tenants$, this._roleService.getGroupsWithRole([Roles.ModelWrite])]).pipe(
            map(([tenants, writableGroups]) => {
                const writableTenantIds = writableGroups.map((group) => group.tenantId);
                const writableTenants = tenants.filter((tenant) => writableTenantIds.includes(tenant.id));
                return writableTenants.map((tenant) => ({
                    id: tenant.id,
                    caption: tenant.name,
                    groups: writableGroups
                        .filter((group) => group.tenantId === tenant.id)
                        .map((group) => ({
                            id: group.id,
                            caption: group.name,
                        })),
                }));
            }),
        );

        this.formGroup.valueChanges
            .pipe(startWith(this.formGroup.value), takeUntil(this._destroy$))
            .subscribe(() => this._updateProjectSelector());

        this.writableGroups$ = combineLatest([
            this.writableTenants$,
            defer(() => this.formGroup.valueChanges.pipe(startWith(this.formGroup.value))),
        ]).pipe(
            map(([tenants, { tenantId, groupId }]) => {
                if (tenantId && !tenants.find((tenant) => tenant.id === tenantId)) {
                    tenantId = tenants[0]?.id;
                    this.formGroup.controls['tenantId'].setValue(tenantId ?? '');
                }

                const groups = tenants.find((tenant) => tenant.id === tenantId)?.groups ?? [];
                const groupIds = groups.map((group) => group.id);
                if (!groupIds.includes(groupId)) {
                    this.formGroup.controls['groupId'].setValue(groupIds[0] ?? '', { emitEvent: false });
                }

                return groups;
            }),
        );
        this._initProjects();
    }

    public ngOnDestroy(): void {
        this._destroy$.next();
        this._destroy$.complete();
    }

    public get projectChanged(): Observable<Project | undefined | null> {
        return this._projectService.currentlySelected;
    }

    public confirm(formGroup: FormGroup): void {
        const model = this._modelService.model!;
        const source = { tenantId: model.tenantId!, groupId: model.groupId! };
        const target = formGroup.getRawValue();

        this._modelApiService
            .copy(model.id, target.name, source, target)
            .pipe(takeUntil(this._destroy$))
            .subscribe(
                (copy) => {
                    this._goEditor(copy.tenantId, copy.groupId, copy.id);
                },
                (error: HttpErrorResponse) => {
                    if (!ErrorsHandledInInterceptor.includes(error.status) && error.status !== 0) {
                        this._errorService.displayErrorMessage(error.message);
                    }
                },
            );
    }

    public onCancel(): void {
        const model = this._modelService.model!;
        this._goEditor(model.tenantId!, model.groupId!, model.id);
    }

    public onSelect(): void {
        const destinationTenant = this._tenantService.getTenant(this.formGroup.controls['tenantId'].value);
        if (destinationTenant !== undefined) {
            this._tenantService.setTenant(destinationTenant);
        }
        this._projectSelectionOverlay = this._modalService.showContentDialog(
            ProjectSelectionComponent,
            'PROJECT_SELECTION.TITLE',
            [
                {
                    text: 'PROJECT_SELECTION.NO_PROJECT',
                    cssClass: 'primary',
                    position: DialogButtonPosition.Left,
                    onClick: () => this._selectProject({}),
                },
                {
                    text: 'PROJECT_SELECTION.CANCEL',
                    cssClass: 'secondary',
                    position: DialogButtonPosition.Right,
                    onClick: () => {
                        this._tenantService.setTenant(this.initialTenant);
                        this._projectSelectionOverlay.dispose();
                    },
                },
                {
                    text: 'PROJECT_SELECTION.SELECT',
                    cssClass: 'primary',
                    position: DialogButtonPosition.Right,
                    onClick: () => {
                        if (this._selectedProject !== undefined && this._selectedProject !== null) {
                            this._selectProject({ selectedId: this._selectedProject.id });
                        } else {
                            this._selectProject({});
                        }
                    },
                },
            ],
            false,
        );
    }

    private _goEditor(tenantId: string, groupId: string, id: string): void {
        this._router.navigate(['editor', tenantId, groupId, id, 'edit']);
    }

    private _initProjects(): void {
        this._projects = [];
        this._projectService.projects$.pipe(takeUntil(this._destroy$)).subscribe((projects) => {
            this._projects = projects;
        });
    }

    private _selectProject(selectedProject: SelectProject): void {
        this._projectSelectionOverlay.dispose();
        const foundProject = this._projects.find((project) => project.id === selectedProject.selectedId);
        this._projectService.selectProject(foundProject);
        if (foundProject) {
            this.formGroup.patchValue({ projectId: foundProject.id });
        } else {
            this.formGroup.controls.projectId.patchValue('');
        }
    }

    private _updateProjectSelector(): void {
        const destinationTenant = this._tenantService.getTenant(this.formGroup.controls['tenantId'].value);
        const group = this._groupService.group;
        const destinationGroup = this._groupService.getGroup(this.formGroup.controls['groupId'].value);

        if (this.initialTenant !== destinationTenant || (group !== destinationGroup && destinationGroup)) {
            this._projectService.selectProject();
            this.formGroup.controls.projectId.patchValue('', { emitEvent: false });
            this.disableSelectButton = true;
        } else {
            this.disableSelectButton = false;
        }
    }
}
