import { Location } from '@angular/common';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { Observable, of, Subject } from 'rxjs';
import { delay, filter, first, map, mergeMap, scan, switchMap, takeUntil } from 'rxjs/operators';
import { RoleService } from '../../role/role.service';
import { ModelObject } from '../model-object.model';
import { Model } from '../model.model';
import { ModelService } from '../model.service';
import { BaseExporter } from './base.exporter';
import { ExportComboBoxOptionModel } from './export-combo-box-option.model';
import { ExportConstants } from './export.constants';
import { ExportService } from './export.service';

@Component({
    selector: 'bx-model-export',
    templateUrl: './model-export.component.html',
    styleUrls: ['./model-export.component.scss'],
})
export class ModelExportComponent implements OnInit, OnDestroy {
    public static readonly EXPORT_FORMAT_NAME = 'format';

    public export: FormGroup;
    public formats$: Observable<ExportComboBoxOptionModel[]>;
    public exporter: BaseExporter;

    private readonly _model: Model<ModelObject>;

    private _destroy$ = new Subject<void>();

    constructor(
        private readonly _exportService: ExportService,
        private readonly _modelService: ModelService,
        private readonly _formBuilder: FormBuilder,
        private readonly _location: Location,
        public readonly translateService: TranslateService,
        private readonly _roleService: RoleService,
    ) {
        this._model = this._modelService.model!;

        this.formats$ = of(this._exportService.getExporters()).pipe(
            mergeMap((exporters) => exporters),
            delay(0),
            switchMap((exporter) =>
                this._roleService
                    .userHasRoles([`model:export:${exporter.getExporterName()}`])
                    .pipe(map((hasRole) => ({ exporter, hasRole }))),
            ),
            filter(({ hasRole }) => !!hasRole),
            map(({ exporter }) => new ExportComboBoxOptionModel(exporter, translateService)),
            scan((acc, value) => [...acc, value], []),
        );

        this.export = this._formBuilder.group({
            [ModelExportComponent.EXPORT_FORMAT_NAME]: undefined,
        });
    }

    public ngOnInit(): void {
        this.export.controls[ModelExportComponent.EXPORT_FORMAT_NAME].valueChanges
            .pipe(
                takeUntil(this._destroy$),
                switchMap((exporterId) =>
                    this.formats$.pipe(
                        mergeMap((exporters) => exporters),
                        first((exporter) => exporter.id === exporterId),
                        map((item) => item.exporter),
                    ),
                ),
            )
            .subscribe((exporter) => (this.exporter = exporter));
    }

    public ngOnDestroy(): void {
        this._destroy$.next();
        this._destroy$.complete();
    }

    public exportModel(): void {
        this.exporter.export(this._model, ExportConstants.vg2Json);
    }

    public onCancel() {
        this._location.back();
    }
}
