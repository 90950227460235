import { Component, EventEmitter, Input, OnInit, Output, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { Observable, Subject, combineLatest } from 'rxjs';
import { filter, switchMap, map, takeUntil } from 'rxjs/operators';
import { PromptService } from '../prompt/prompt.service';
import { ProjectDeletionPromptComponent } from '../../app/project/project-deletion-prompt/project-deletion-prompt.component';
import { PromptResult } from '../prompt/prompt.model';
import { ProjectService } from '../../app/project/project.service';
import { RoleService } from '../role/role.service';
import { Roles } from '../role/role.model';

@Component({
    selector: 'bx-subproject-entry',
    templateUrl: './subproject-entry.component.html',
    styleUrls: ['./subproject-entry.component.scss'],
})
export class SubprojectEntryComponent implements OnInit, OnDestroy {
    @Input() public id: string;
    @Input() public groupId: string;
    @Input() public tenantId: string;
    @Input() public name: string;
    @Input() public bookmarked: boolean;
    @Input() public enableContextMenu: boolean;
    @Input() public enableBookmark: boolean;

    @Output()
    public bookmarkChange = new EventEmitter<boolean>();
    @Output() public delete = new EventEmitter<void>();
    @Output() public projectChange = new EventEmitter<{ tenantId: string; groupId: string; id: string }>();
    @Output() public move = new EventEmitter<void>();

    public permission$: Observable<any>;

    private readonly _destroy$ = new Subject<void>();

    constructor(
        private readonly _promptService: PromptService,
        private readonly _projectService: ProjectService,
        private readonly _roleService: RoleService,
        private readonly _router: Router,
    ) {}

    public ngOnInit(): void {
        this.permission$ = combineLatest([
            this._roleService.userHasRoles([Roles.ProjectDelete]),
            this._roleService.userHasRoles([Roles.ProjectWrite]),
            this._roleService.userHasRoles([Roles.ProjectMove]),
        ]).pipe(
            map(([allowDelete, allowUpdate, allowMove]) =>
                !allowDelete && !allowUpdate && !allowMove ? false : { allowDelete, allowUpdate, allowMove },
            ),
        );
    }

    public ngOnDestroy(): void {
        this._destroy$.next();
        this._destroy$.complete();
    }

    public setBookmarkState($event: MouseEvent) {
        $event.stopPropagation();
        this.bookmarkChange.emit(!this.bookmarked);
    }

    public onSelect() {
        this.projectChange.emit({ tenantId: this.tenantId, groupId: this.groupId, id: this.id });
    }

    public deleteItem() {
        this._promptService
            .displayPrompt(ProjectDeletionPromptComponent)
            .pipe(
                filter((result) => result === PromptResult.Confirm),
                switchMap(() => this._projectService.delete(this.id)),
                takeUntil(this._destroy$),
            )
            .subscribe(() => this.delete.emit());
    }

    public renameItem() {
        return this._router.navigate(['project', this.id, 'rename']);
    }

    public moveItem() {
        this.move.emit();
    }

    get title() {
        return this.bookmarked ? 'SUBPROJECT_ENTRY.REMOVE_BOOKMARK' : 'SUBPROJECT_ENTRY.ADD_BOOKMARK';
    }
}
