<bx-title-bar><h4 caption translate>SHARED.MODEL.COPY.TITLE</h4></bx-title-bar>
<form [formGroup]="formGroup">
    <div class="content">
        <bx-form-field class="full">
            <label>{{ 'SHARED.MODEL.METADATA.NAME.CAPTION' | translate }}</label>
            <input bxInput formControlName="name" [placeholder]="'SHARED.MODEL.METADATA.NAME.PLACEHOLDER' | translate" data-cy="model-name" />
        </bx-form-field>

        <bx-form-field class="full">
            <label>{{ 'TENANT.FIELD' | translate }}</label>
            <bx-combo-box
                formControlName="tenantId"
                [options]="writableTenants$ | async"
                [placeholder]="'TENANT.PLACEHOLDER' | translate"
            ></bx-combo-box>
        </bx-form-field>

        <bx-form-field class="full">
            <label>{{ 'GROUP.FIELD' | translate }}</label>
            <bx-combo-box
                formControlName="groupId"
                [options]="writableGroups$ | async"
                [placeholder]="'GROUP.PLACEHOLDER' | translate"
            ></bx-combo-box>
        </bx-form-field>

        <fieldset>
            <bx-form-field class="full">
                <label>{{ 'SHARED.MODEL.METADATA.PROJECT.CAPTION' | translate }}</label>
                <bx-breadcrumbs [project]="projectChanged | async" [isReadOnly]="true"> </bx-breadcrumbs>
            </bx-form-field>
        </fieldset>

        <fieldset>
            <bx-form-field class="full">
                <div class="button-bar">
                    <div class="left">
                        <button type="button" class="primary" [disabled]="disableSelectButton" (click)="onSelect()">
                            {{ 'SHARED.MODEL.METADATA.PROJECT.SELECT' | translate }}
                        </button>
                    </div>
                </div>
            </bx-form-field>
        </fieldset>

        <div class="line"></div>
    </div>
    <footer class="footer">
        <div class="button-bar">
            <div class="left">
                <button type="button" class="secondary" (click)="onCancel()">
                    {{ 'GLOBALS.CANCEL' | translate }}
                </button>
            </div>
            <div class="right">
                <button type="button" class="primary" (click)="confirm(formGroup)" [disabled]="formGroup.invalid" data-cy="copy-button">
                    {{ 'GLOBALS.COPY' | translate }}
                </button>
            </div>
        </div>
    </footer>
</form>
