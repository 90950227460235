import { Component, Inject } from '@angular/core';
import { BasePrompt } from '../../prompt/base.prompt';
import { TranslateService } from '@ngx-translate/core';
import { PROMPT_DATA } from 'src/modules/prompt/prompt.service';
import { ErrorPromptContent } from '../prompt-error-message.model';

@Component({
    selector: 'bx-error-support-prompt',
    templateUrl: 'error-support.prompt.html',
    styleUrls: ['error-support.prompt.scss'],
})
export class ErrorSupportPrompt extends BasePrompt {
    constructor(
        private translate: TranslateService,
        @Inject(PROMPT_DATA) public data: ErrorPromptContent,
    ) {
        super();
    }
}
