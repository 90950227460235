<bx-filter-box [placeholder]="'PROJECT_SELECTION.FILTER' | translate" (filter)="filter.next($event)"></bx-filter-box>

<bx-breadcrumbs [project]="projectSelectionChanged | async" (projectChange)="selectInitialProject($event)"> </bx-breadcrumbs>

<div class="subprojects sub-group preserve-whitespace">
    <ng-container *ngIf="filteredProjects$ | async as subprojects">
        <div *ngIf="subprojects?.length > 0" class="subprojects-list">
            <div *ngFor="let project of subprojects" class="subproject-entry">
                <bx-subproject-entry
                    [name]="project.name"
                    [id]="project.id"
                    [groupId]="project.groupId"
                    [tenantId]="project.tenantId"
                    [bookmarked]="project.isBookmarked"
                    [enableContextMenu]="false"
                    [enableBookmark]="false"
                    (projectChange)="selectInitialProject(project)"
                >
                </bx-subproject-entry>
            </div>
        </div>
    </ng-container>
</div>
