import { ChangeDetectionStrategy, Component } from '@angular/core';
import { ProjectService } from '../../app/project/project.service';
import { Project } from '../../app/project/project.model';
import { BehaviorSubject, Observable, combineLatest } from 'rxjs';
import { map, take } from 'rxjs/operators';
import { sortByName } from '../util/util';
import { ProjectSelectionService } from './project-selection.service';

@Component({
    templateUrl: 'project-selection.component.html',
    styleUrls: ['project-selection.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ProjectSelectionComponent {
    public readonly filteredProjects$: Observable<Project[]>;
    public readonly projects$ = this._projectService.projects$;
    public projectOverviewCaption: string;

    public filter = new BehaviorSubject<string | null>(null);

    constructor(
        private readonly _projectService: ProjectService,
        private readonly _projectSelectionService: ProjectSelectionService,
    ) {
        this.filteredProjects$ = combineLatest([this.projects$, this.projectSelectionChanged, this.filter]).pipe(
            map(([projects, selectedProject, filterText]) => {
                let filteredProjects: Project[] = [];
                if (selectedProject === undefined || selectedProject === null) {
                    this.projectOverviewCaption = 'SHARED.MODEL.LIBRARY.PROJECTS';
                    filteredProjects = projects.filter((project) => project.parentId === undefined);
                } else {
                    this.projectOverviewCaption = 'SHARED.MODEL.LIBRARY.SUBPROJECTS';
                    filteredProjects = projects.filter((project) => project.parentId === selectedProject.id);
                }

                if (filterText !== null) {
                    return filteredProjects.filter((project) => project.name.toLowerCase().includes(filterText.toLowerCase()));
                } else {
                    return filteredProjects;
                }
            }),
            map((projects) => projects.sort(sortByName)),
        );

        this.projectChanged.pipe(take(1)).subscribe((initialProject) => {
            this.selectInitialProject(initialProject);
        });
    }

    public get projectChanged(): Observable<Project | undefined | null> {
        return this._projectService.currentlySelected;
    }

    public get projectSelectionChanged(): Observable<Project | undefined | null> {
        return this._projectSelectionService.currentlySelected;
    }

    public selectInitialProject(project: Project | null | undefined): void {
        this._projectSelectionService.selectInitialProject(project);
    }
}
