import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class BlobConversionService {
    public getDataUrlFromBlob(blob: Blob): Observable<string> {
        return new Observable((obs) => {
            const fileReader = new FileReader();
            fileReader.addEventListener('load', (event) => {
                obs.next(event.target?.result as string);
                obs.complete();
            });
            fileReader.readAsDataURL(blob);
        });
    }

    public getBlobFromDataUrl(dataUrl: string): Blob {
        const [prefix, data] = dataUrl.split(',');
        const byteString = prefix.indexOf('base64') > 0 ? atob(data) : unescape(data);
        const type = prefix.split(':')[1].split(';')[0];
        const bytes = new Uint8Array(byteString.length);
        for (let index = 0; index < byteString.length; index++) {
            bytes[index] = byteString.charCodeAt(index);
        }

        return new Blob([bytes], { type });
    }
}
