import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { Observable, Subject } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';
import { EditorContext } from '../../../app/editor/editor-context.model';
import { DataModelType } from '../../data-model/data-model-type.model';
import { DataModelService } from '../../data-model/data-model.service';
import { SchaefflerObject } from '../../data-model/schaeffler-models.model';
import { ComboBoxOption } from '../../form-control/combo-box/combo-box-option.model';
import { ProjectService } from '../../../app/project/project.service';
import { RoleService } from '../../role/role.service';
import { Roles } from '../../role/role.model';
import { ModelObject } from '../model-object.model';
import { Model } from '../model.model';
import { Visibility } from '../visibility.model';
import { ModelMetadata } from './model-metadata.model';
import { SelectProject, Project } from '../../../app/project/project.model';
import { OverlayRef } from '@angular/cdk/overlay';
import { DialogButtonPosition } from '../../modal/models/dialog.preset';
import { ProjectSelectionComponent } from '../../project-selection/project-selection.component';
import { ModalService } from '../../modal/services/modal.service';
import { ProjectSelectionService } from '../../project-selection/project-selection.service';
import { customTrimName } from 'src/modules/util/util';

@Component({
    selector: 'bx-model-metadata-form',
    templateUrl: 'model-metadata-form.component.html',
    styleUrls: ['model-metadata-form.component.scss'],
})
export class ModelMetadataFormComponent implements OnInit, OnDestroy {
    static readonly NEW_PROJECT = 'new_project';
    readonly Roles = Roles;

    @Input() public model: Model<ModelObject> | undefined;
    @Input() public cancelLabel = this._translateService.instant('GLOBALS.CANCEL');
    @Output() public confirm = new EventEmitter<ModelMetadata>();

    @Output() public cancel = new EventEmitter<void>();

    public formGroup: FormGroup;
    private _projects: Project[] = [];
    public types: ComboBoxOption[] = [];
    public methods: ComboBoxOption[] = [];
    public visibilities = Visibility;
    public projects$ = this._projectService.projects$;
    private readonly _destroy$ = new Subject<void>();
    public readonly permittedToWrite$ = this._roleService.userHasRoles([Roles.ModelWrite, Roles.ProjectList]);
    private _projectSelectionOverlay: OverlayRef;
    private _selectedProject: Project | undefined | null;
    protected readOnlyActive = true;

    constructor(
        private readonly _formBuilder: FormBuilder,
        private readonly _dataModelService: DataModelService,
        private readonly _projectService: ProjectService,
        private readonly _translateService: TranslateService,
        private readonly _roleService: RoleService,
        public readonly editorContext: EditorContext,
        private readonly _modalService: ModalService,
        private readonly _projectSelectionService: ProjectSelectionService,
    ) {
        this._projectSelectionService.currentlySelected.pipe(takeUntil(this._destroy$)).subscribe((selected) => {
            this._selectedProject = selected;
        });
    }

    public get isEditmode(): boolean {
        return this.model !== void 0;
    }

    // TODO: method & type needs to be moved to a separate method selection screen, see BAAS-759 for further information
    public ngOnInit(): void {
        this.formGroup = this._formBuilder.group({
            name: ['', Validators.required],
            projectId: [''],
            project: void 0,
            visibility: Visibility.Public,
            description: '',
            tags: [[]],
            method: ['', Validators.required],
            type: ['', Validators.required],
        });

        const typeControl = this.formGroup.get('type')!;

        this._initProjects();

        if (this.isEditmode) {
            this.formGroup.patchValue(this.model!);

            const project = this.model!.project;
            if (project && project.groupId === this.model?.groupId) {
                this._projectService.selectProject(project.id);
                this.formGroup.patchValue({ projectId: project.id });
            }

            // fill type control
            const type = this.model!.rootObject.type;
            this._initTypes(type);
            typeControl.setValue(type);

            // fill method control
            this._initMethods(type);
        } else {
            this._initTypes('');
        }
        this.permittedToWrite$
            .pipe(
                takeUntil(this._destroy$),
                filter((permittedToWrite) => !permittedToWrite),
            )
            .subscribe(() => this.formGroup.disable());
    }

    ngOnDestroy(): void {
        this._destroy$.next();
    }

    public onCancel(): void {
        this.cancel.emit();
    }

    public onConfirm(formGroup: FormGroup): void {
        const modelMeta: ModelMetadata = formGroup.getRawValue();
        if (!modelMeta.projectId) {
            delete modelMeta.project;
        }
        if (modelMeta.project && modelMeta.projectId === ModelMetadataFormComponent.NEW_PROJECT) {
            delete modelMeta.project.id;
        }
        delete modelMeta.projectId;
        modelMeta.name = customTrimName(modelMeta.name);
        this.confirm.emit(modelMeta);
    }

    public prepareNewProject(): void {
        this.formGroup.controls.project.patchValue({});
        this.formGroup.controls.projectId.patchValue('');
    }

    public onSelect(): void {
        this._projectSelectionOverlay = this._modalService.showContentDialog(
            ProjectSelectionComponent,
            'PROJECT_SELECTION.TITLE',
            [
                {
                    text: 'PROJECT_SELECTION.NO_PROJECT',
                    cssClass: 'primary',
                    position: DialogButtonPosition.Left,
                    onClick: () => this._selectProject({}),
                },
                {
                    text: 'PROJECT_SELECTION.CANCEL',
                    cssClass: 'secondary',
                    position: DialogButtonPosition.Right,
                    onClick: () => this._projectSelectionOverlay.dispose(),
                },
                {
                    text: 'PROJECT_SELECTION.SELECT',
                    cssClass: 'primary',
                    position: DialogButtonPosition.Right,
                    onClick: () => {
                        if (this._selectedProject !== undefined && this._selectedProject !== null) {
                            this._selectProject({ selectedId: this._selectedProject.id });
                        } else {
                            this._selectProject({});
                        }
                    },
                },
            ],
            false,
        );
    }

    public get projectChanged(): Observable<Project | undefined | null> {
        return this._projectService.currentlySelected;
    }

    public toggleDisableForm(readOnlyActive: boolean) {
        this.readOnlyActive = readOnlyActive;
        if (readOnlyActive) {
            this.formGroup.disable();
        } else {
            this.formGroup.enable();
        }
    }

    private _initMethods(type: string): void {
        this.methods = [];

        const methods = this._dataModelService.getModel(DataModelType.Methods);
        if (methods) {
            this.methods = methods
                .filter((method) => method.rootObjects.includes(type))
                .map((method) => ({ id: method.method, caption: method.localization.text }));
        }
    }

    private _initProjects(): void {
        this._projects = [];
        this.projects$.pipe(takeUntil(this._destroy$)).subscribe((projects) => {
            this._projects = projects;
        });
    }

    private _initTypes(filterType: string): void {
        this.types = [];

        const objectTypes = this._dataModelService.getModel(DataModelType.ObjectTypes);
        if (objectTypes) {
            this.types = objectTypes
                .filter((objectType) => this._matchTypes(objectType, filterType))
                .map((objectType) => ({ id: objectType.item, caption: objectType.localization.text }));
        }
    }

    private _matchTypes(objectType: SchaefflerObject, filterType: string): boolean {
        return (
            filterType.length < 1 ||
            objectType.localization.text.toLocaleLowerCase().indexOf(filterType.toLocaleLowerCase()) >= 0 ||
            objectType.item === filterType // this allows finding types by their actual ID, used for initialization
        );
    }

    private _selectProject(selectedProject: SelectProject): void {
        this._projectSelectionOverlay.dispose();
        const foundProject = this._projects.find((project) => project.id === selectedProject.selectedId);
        this._projectService.selectProject(foundProject);
        if (foundProject) {
            this.formGroup.patchValue({ project: foundProject });
            this.formGroup.patchValue({ projectId: foundProject.id });
        } else {
            this.prepareNewProject();
        }
    }
}
