import { CameraType } from '@webfoundation/viewer';
import { ClippingPlaneType } from './../clipping-plane-type.model';
import { switchMap, map, mergeMap, delay, scan, take, takeUntil, filter } from 'rxjs/operators';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit, OnDestroy } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { SettingsService } from '../settings.service';
import { Subject, of } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';

@Component({
    selector: 'bx-scene3d-settings',
    templateUrl: './scene3d-settings.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class Scene3DSettingsComponent implements OnInit, OnDestroy {
    private readonly _destroy$ = new Subject();

    public readonly form = this._formBuilder.group({
        rotateAroundSelection: [false],
        zoomToMousePosition: [false],
        clippingPlane: [ClippingPlaneType.XY, Validators.required],
        flipClippingPlane: [false],
        textured: [false],
        cameraType: [CameraType.Perspective, Validators.required],
    });

    public clippingPlanesTypes$ = of(this._settingsService.clippingPlaneTypes).pipe(
        mergeMap((types) => types),
        delay(0),
        switchMap((type) =>
            this._translateService.get(`SHARED.SETTINGS.SCENE3D.CLIPPING_PLANE_${type}`).pipe(
                map((translation) => ({
                    id: type,
                    caption: translation,
                })),
            ),
        ),
        scan((acc, value) => [...acc, value], []),
        map((types) => (types?.length > 0 ? types : undefined)),
    );

    public cameraTypes$ = of(this._settingsService.cameraTypes).pipe(
        mergeMap((types) => types),
        delay(0),
        switchMap((type) =>
            this._translateService.get(`SHARED.SETTINGS.SCENE3D.CAMERA_TYPE_${type}`).pipe(
                map((translation) => ({
                    id: type,
                    caption: translation,
                })),
            ),
        ),
        scan((acc, value) => [...acc, value], []),
        map((types) => (types?.length > 0 ? types : undefined)),
    );

    constructor(
        private readonly _formBuilder: FormBuilder,
        private readonly _settingsService: SettingsService,
        private readonly _translateService: TranslateService,
        private readonly _cd: ChangeDetectorRef,
    ) {}

    public ngOnInit(): void {
        this._settingsService.scene3dSettings$
            .pipe(
                takeUntil(this._destroy$),
                filter((scene3d) => !!scene3d),
            )
            .subscribe((scene3d) => {
                this.form.patchValue(scene3d!);
                this._cd.markForCheck();
            });
    }

    public ngOnDestroy(): void {
        this._destroy$.next();
        this._destroy$.complete();
    }

    public save(): void {
        if (!this.form.invalid && !this.form.pristine) {
            this.form.disable();

            this._settingsService
                .saveSettings({ scene3d: this.form.value })
                .pipe(take(1))
                .subscribe(() => {
                    this.form.enable();
                    this._cd.markForCheck();
                });
        }
    }
}
