export interface Tenant {
    id: tenantId;
    name: string;
    client: string;
}

export type tenantId = string;

export const STRING_BEARINX_ONLINE = 'STRING_BEARINX_ONLINE';

export const DEMO_TEMP_TENANT = ['Demo', 'Temp'];

export const CURRENT_TENANT_KEY = 'TENANT_CURRENT';
