import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnDestroy } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { SettingsService } from '../settings.service';
import { take, takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';

@Component({
    selector: 'bx-warning-settings',
    templateUrl: './warning-settings.component.html',
    styleUrls: ['./warning-settings.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class WarningSettingsComponent implements OnDestroy {
    private readonly _destroy$ = new Subject();

    public form = this._formBuilder.group({
        delLoadCases: [false],
        delShafts: [false],
        delSystems: [false],
        delLinAxes: [false],
    });

    constructor(
        private readonly _formBuilder: FormBuilder,
        private readonly _settingsService: SettingsService,
        private readonly _cd: ChangeDetectorRef,
    ) {
        this._settingsService.warningSettings$.pipe(takeUntil(this._destroy$)).subscribe((warnings) => {
            this.form.patchValue(warnings!);
            this._cd.markForCheck();
        });
    }

    public ngOnDestroy(): void {
        this._destroy$.next();
        this._destroy$.complete();
    }

    public save(): void {
        if (!this.form.invalid && !this.form.pristine) {
            this.form.disable();

            this._settingsService
                .saveSettings({ warnings: this.form.value })
                .pipe(take(1))
                .subscribe(() => {
                    this.form.enable();
                    this._cd.markForCheck();
                });
        }
    }
}
