<bx-title-bar>
    <button
        position="start"
        (click)="navigationDrawer.toggle()"
        *ngIf="navigationDrawer"
        [disabled]="navigationDrawer.alwaysShowNavigation$ | async"
        class="toggle-drawer"
        data-cy="app-bar-toggle-drawer"
    >
        <div class="hamburger" [class.back]="navigationDrawer.isActive">
            <span class="line"></span>
            <span class="line"></span>
            <span class="line"></span>
        </div>
    </button>

    <h1 caption translate>GLOBALS.PRODUCT_TITLE</h1>

    @if (subCaption$ | async; as subCaption) {
        <h2 subCaption>
            {{ subCaption }}
            <b *ngIf="appBarService.loadCaseCaption$ | async as loadCaseCaption">: {{ loadCaseCaption }}</b>
        </h2>
    } @else if (tenantService.demoMode$ | async) {
        <h2 subCaption class="warning">
            <i class="material-icons warning-info" (click)="showDemoInfo()">info</i>
            <span [bxSimpleTooltip]="'SHARED.DEMO_INFO.TOOLTIP' | translate">
                {{ 'SHARED.DEMO_INFO.WARNING_MESSAGE' | translate }}
            </span>
        </h2>
    } @else if (!isInitializedSuccessfully) {
        <h2 subCaption class="warning">
            <span [bxSimpleTooltip]="'INITIALIZATION_FAILED.WARNING_MESSAGE_TOOLTIP' | translate">
                <i class="material-icons warning-initialization">info</i>
                {{ 'INITIALIZATION_FAILED.WARNING_MESSAGE' | translate }}
            </span>
        </h2>
    } @else {
        <h2 subCaption *ngIf="breadcrumbs$ | async as breadcrumbs">
            <span *ngFor="let breadcrumb of breadcrumbs" class="breadcrumb-label">
                {{ breadcrumb?.label }}
            </span>
        </h2>
    }

    <button
        *ngIf="lockingVisible$ | async"
        (click)="appBarService.toggleReadOnly()"
        [bxSimpleTooltip]="lockingTooltipTranslationKey$ | async | translate"
        data-cy="app-bar-toggle-editable"
        class="toggle-editable"
    >
        <bx-icon
            *ngIf="appBarService.readOnlyActive$ | async; else readOnlyInactive"
            icon="lock"
            data-cy="app-bar-enable-edit"
            class="lock-closed"
        ></bx-icon>
        <ng-template #readOnlyInactive>
            <bx-icon icon="lock_open" data-cy="app-bar-finish-edit" class="lock-open"></bx-icon>
        </ng-template>
    </button>

    <button (click)="notifications.toggle()" [bxSimpleTooltip]="'SHARED.NOTIFICATIONS.TITLE' | translate" class="toggle-notifications">
        <i class="material-icons" [class.ringring]="ringring$ | async">notifications_active</i>
        <span *ngIf="notifications.unseenCount > 0" class="badge">{{ notifications.unseenCount }}</span>
    </button>

    <button
        (click)="appBarService.toggleDashboard()"
        [disabled]="dashboardDisabled$ | async"
        [bxSimpleTooltip]="'SHARED.DASHBOARD.TITLE' | translate"
        class="dashboard"
    >
        <bx-icon icon="insert_chart" data-cy="app-bar-toggle-dashboard"></bx-icon>
    </button>

    <button
        (click)="appBarService.toggleObjectTree()"
        [disabled]="objectTreeDisabled$ | async"
        [bxSimpleTooltip]="'BEARINX.EDITOR.OBJECTTREE.TITLE' | translate"
        class="object-tree"
    >
        <div class="tree" [class.back]="appBarService.objectTreeOpen$ | async">
            <div class="wrapper">
                <span class="line"></span>
                <span class="line"></span>
                <span class="line"></span>
                <span class="line"></span>
            </div>
        </div>
    </button>

    <button (click)="openSchaefflerPage()" class="fit-content">
        <figure class="company-logo">
            <img src="assets/schaeffler-logo.svg" alt="schaeffler-logo" />
        </figure>
    </button>
</bx-title-bar>

<bx-notification-area #notifications></bx-notification-area>
