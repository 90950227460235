import { Attribute, Directive, ElementRef, EventEmitter, OnDestroy, OnInit, Output } from '@angular/core';
import { HotkeyService } from './hotkey.service';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Directive({
    selector: '[bxHotkey]',
})
export class HotkeyDirective implements OnInit, OnDestroy {
    // eslint-disable-next-line @angular-eslint/no-output-on-prefix
    @Output() public readonly onHotkey: EventEmitter<string> = new EventEmitter<string>();
    private readonly _nativeElement: HTMLElement;
    private readonly _destroy$ = new Subject<void>();

    constructor(
        @Attribute('bxHotkey') private readonly _hotkey: string,
        private readonly _hotkeyService: HotkeyService,
        elementRef: ElementRef,
    ) {
        this._nativeElement = elementRef.nativeElement;
    }

    public ngOnInit(): void {
        if (!this._hotkey) {
            throw new Error('Hotkey not set.');
        }

        this._hotkeyService
            .register(this._hotkey, this._nativeElement)
            .pipe(takeUntil(this._destroy$))
            .subscribe((combo) => this.onHotkey.next(combo));
    }

    public ngOnDestroy(): void {
        this._hotkeyService.unregister(this._hotkey, this._nativeElement);
        this._destroy$.next();
        this._destroy$.complete();
    }
}
