import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
    selector: 'bx-settings-card',
    templateUrl: './settings-card.component.html',
    styleUrls: ['./settings-card.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SettingsCardComponent {
    @Input() public title: string;
}
