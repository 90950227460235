<!-- <span [class.selected]="selected">{{ label$ | async }}</span>
<i class="material-icons">arrow_drop_down</i> -->
<bx-input-container #anchor>
    <input
        bxInput
        #input
        type="text"
        class="not-rounded"
        [placeholder]="placeholder"
        [value]="label$ | async"
        [disabled]="isDisabled"
        readonly
    />
    <bx-input-addons (click)="input.focus()">
        <bx-icon icon="arrow_drop_down"></bx-icon>
    </bx-input-addons>
</bx-input-container>

<bx-multi-select-overlay
    *cdkPortal
    [options]="options"
    [selectedIds]="selectedIds"
    (update)="updateValue($event)"
></bx-multi-select-overlay>
