import { Injectable } from '@angular/core';

export type IconContext = 'object_bar' | 'object_tree' | 'product_selection' | 'tool_bar' | 'system' | 'object_view';

const mapping = {
    object_tree: {
        IDO_SLIDING_BEARING: 'sliding.svg',
        IDO_ROTATORY_BEARING: 'bearing.svg',
        IDO_DYNAMIC_POINT_MASS: 'dynamic_point_mass.svg',
        IDO_CONSTANT_LINELOAD: 'constant_line_load.svg',
        IDO_LINEAR_LINELOAD: 'linear_variable_line_load.svg',
        // eslint-disable-next-line sonarjs/no-duplicate-string
        IDO_LOADCONTAINER: 'load_case.svg',
        IDO_SYSTEM_LOADCASE: 'load_case.svg',
        IDO_NOTCH: 'notch.svg',
        IDO_OUT_OF_BALANCE: 'out_of_balance.svg',
        IDO_POINTLOAD: 'point_load.svg',
        IDO_ROTATORY_BEARING_RING: 'ring.svg',
        IDO_ROTATORY_BALL_ROW: 'ball_row.svg',
        IDO_ROTATING_CONSTANT_LINE_LOAD: 'rotating_constant_line_load.svg',
        IDO_ROTATING_LINEAR_LINE_LOAD: 'rotating_linear_variable_line_load.svg',
        IDO_PIPE_SEGMENT: 'segment.svg',
        IDO_SHAFT: 'shaft.svg',
        IDO_SHAFT_SYSTEM: 'shaft_system.svg',
        IDO_ROTATORY_ROLLER_ROW: 'roller_row.svg',
        IDO_SHAFT_COUPLING_BASE: 'torque_limiter.svg',
        IDO_GEAR_SET: 'transmission_element.svg',
        IDO_GE_TORQUE_DETERMINER: 'ground.svg',
        IDO_TRANSMISSION_ELEMENT: 'gear.svg',
        IDO_SYSTEM_LIFE: 'system_life.svg',
        IDO_SPRING: 'spring.svg',
        IDO_RIGID_SUPPORT: 'rigid_support.svg',
        IDO_COUNTER_MOMENT: 'counter_moment.svg',
        // eslint-disable-next-line sonarjs/no-duplicate-string
        ID_CLASS_OPTIKIT_OPTIKIT_OBJECT: 'optikit_object.svg',
        ID_CLASS_OPTIKIT_OPTIKIT_DIAGRAM: 'optikit_diagram.svg',
    },
    object_bar: {
        IDC_LOADCASE: 'load_case_category.svg',
        IDO_SYSTEM_LOADCASE: 'load_case.svg',
        IDO_SHAFT_LOAD_CASE_ASSEMBLY: 'data_all_loadcases.svg',
        IDC_GEAR_ELEMENT: 'gear_category.svg',
        // IDO_SHAFTCOUPLING: '',
        IDC_GEAR_WHEEL: 'transmission_element_category.svg',
        IDO_SPUR_GEAR: 'spur_gear.svg',
        IDO_INTERNAL_SPUR_GEAR: 'internal_spur_gear.svg',
        IDO_BEVEL_GEAR: 'bevel_gear.svg',
        IDO_INTERNAL_BEVEL_GEAR: 'internal_bevel_gear.svg',
        IDO_WORM: 'worm.svg',
        IDO_WORM_GEAR: 'worm_gear.svg',
        // IDO_PULLEY: '',
        IDO_CONTINUOUS_VARIABLE_PULLEY: 'continuous_variable_pulley.svg',
        IDO_SPROCKET: 'sprocket.svg',
        IDO_GEAR_ABSTRACT: 'abstract_gear.svg',
        IDO_COUNTER_MOMENT: 'counter_moment.svg',
        IDC_LOAD: 'loads_category.svg',
        IDO_LOAD_CASE_OVERLAPPING_POINT_LOAD: 'load_case_overlapping_point_load.svg',
        IDO_LOAD_CASE_OVERLAPPING_CONSTANT_LINE_LOAD: 'load_case_overlapping_constant_line_load.svg',
        IDO_LOAD_CASE_OVERLAPPING_LINEAR_LINE_LOAD: 'load_case_overlapping_linear_line_load.svg',
        IDO_ROTATING_LOAD_CASE_OVERLAPPING_POINT_LOAD: 'rotating_load_case_overlapping_point_load.svg',
        IDO_ROTATING_LOAD_CASE_OVERLAPPING_CONSTANT_LINE_LOAD: 'rotating_load_case_overlapping_constant_line_load.svg',
        // IDO_ROTATING_LOAD_CASE_OVERLAPPING_LINEAR_LINE_LOAD: '',
        IDO_OUT_OF_BALANCE: 'out_of_balance.svg',
        IDO_DYNAMIC_POINT_MASS: 'dynamic_point_mass.svg',
        IDC_BEARING: 'bearing_category.svg',
        IDO_RADIAL_ROLLER_BEARING: 'radial_roller_bearing.svg',
        IDO_RADIAL_ANGULAR_CONTACT_ROLLER_BEARING: 'radial_angular_contact_roller_bearing.svg',
        IDO_THRUST_ROLLER_BEARING: 'thrust_roller_bearing.svg',
        IDO_THRUST_ANGULAR_CONTACT_ROLLER_BEARING: 'thrust_angular_contact_roller_bearing.svg',
        IDO_RADIAL_BALL_BEARING: 'radial_deep_groove_ball_bearing.svg',
        IDO_RADIAL_ANGULAR_CONTACT_BALL_BEARING: 'radial_angular_contact_ball_bearing.svg',
        IDO_THRUST_BALL_BEARING: 'thrust_ball_bearing.svg',
        IDO_THRUST_ANGULAR_CONTACT_BALL_BEARING: 'thrust_angular_contact_ball_bearing.svg',
        IDO_RADIAL_TAPERED_ROLLER_BEARING: 'radial_tapered_roller_bearing.svg',
        IDO_THRUST_TAPERED_ROLLER_BEARING: 'thrust_tapered_roller_bearing.svg',
        IDO_RADIAL_SELF_ALIGNING_ROLLER_BEARING: 'radial_self_aligning_roller_bearing.svg',
        IDO_THRUST_SELF_ALIGNING_ROLLER_BEARING: 'thrust_self_aligning_roller_bearing.svg',
        IDO_RADIAL_SELF_ALIGNING_BALL_BEARING: 'radial_self_aligning_ball_bearing.svg',
        IDO_THRUST_ROLLER_RADIAL_ROLLER_BEARING: 'thrust_roller_radial_roller_bearing.svg',
        IDO_THRUST_BALL_RADIAL_ROLLER_BEARING: 'thrust_ball_radial_roller_bearing.svg',
        IDO_THRUST_ANGULAR_CONTACT_BALL_RADIAL_ROLLER_BEARING: 'thrust_angular_contact_ball_radial_roller_bearing.svg',
        IDO_THRUST_ANGULAR_CONTACT_BALL_BEARING_WITH_MIXED_ROWS: 'thrust_angular_contact_ball_bearing_with_mixed_rows.svg',
        // IDO_THRUST_ANGULAR_CONTACT_BALL_BEARING_BSB: '',
        // IDO_THRUST_ROLLER_BEARING_WITH_MIXED_ROWS: '',
        IDO_SLEWING_ROLLER_BEARING: 'slewing_roller_bearing.svg',
        IDO_SLEWING_BALL_BEARING: 'slewing_ball_bearing.svg',
        IDO_DOUBLE_DIRECTION_AXIAL_RADIAL_SLEWING_BEARING: 'double_direc_on_axial_radial_slewing_bearing.svg',
        // IDO_BALL_SCREW_DRIVE_WITH_TWO_POINT_CONTACT: '',
        // IDO_BALL_SCREW_DRIVE_WITH_FOUR_POINT_CONTACT: '',
        // IDO_RLF_BEARING: '',
        IDO_GENERIC_ROTATORY_BEARING: 'generic_rotatory_bearing.svg',
        IDO_CYLINDRICAL_PLAIN_BUSH: 'cylindrical_plain_bush.svg',
        IDO_RADIAL_SPHERICAL_PLAIN_BEARING: 'radial_spherical_plain_bearing.svg',
        IDO_ANGULAR_SPHERICAL_PLAIN_BEARING: 'angular_spherical_plain_bearing.svg',
        IDO_THRUST_WASHER: 'thrust_washer.svg',
        IDO_SPRING: 'spring.svg',
        IDO_PRELOAD_SPRING: 'preload_spring.svg',
        IDO_RIGID_SUPPORT: 'rigid_support.svg',
        IDC_NOTCH: 'notch_category.svg',
        IDO_NOTCH: 'notch.svg',
        IDO_STEP: 'notch_step.svg',
        IDO_CLEARANCE_CUT: 'notch_clearance_cut.svg',
        IDO_INTERNAL_STEP: 'notch_internal_step.svg',
        IDO_U_GROOVE: 'notch_U_groove.svg',
        IDO_V_GROOVE: 'notch_V_groove.svg',
        IDO_RECT_GROOVE: 'notch_rect_groove.svg',
        IDO_HOLE: 'notch_hole.svg',
        IDO_HUB: 'notch_hub.svg',
        IDO_KEY_SEAT: 'notch_key_seat.svg',
        IDO_USER_DEFINED_NOTCH: 'notch_user_defined_notch.svg',
        IDO_USER_DEFINED_SHOULDER: 'notch_user_defined_shoulder.svg',
        IDO_USER_DEFINED_INTERNAL_STEP: 'notch_user_defined_internal_step.svg',
        IDC_SHAFT_HOUSING: 'shaft_housing_category.svg',
        IDO_SHAFT: 'shaft.svg',
        IDO_PIPE_SEGMENT: 'pipe_segment.svg',
        IDO_CONE_SEGMENT: 'cone_segment.svg',
        // IDO_SHAFT_SYSTEM_HOUSING: '',
        // IDO_HOUSING_SEGMENT: '',
        IDC_SYSTEM_LIFE: 'system_life_category.svg',
        IDO_SYSTEM_LIFE: 'system_life.svg',
        IDC_ASSEMBLY: 'assembly.svg',
        IDO_SHAFT_ASSEMBLY: 'shaft_assembly.svg',
        IDO_SPINDLE_ASSEMBLY: 'spindle_assembly.svg',
        IDC_OPTIKIT: 'optikit.svg',
        ID_CLASS_OPTIKIT_OPTIKIT_OBJECT: 'optikit_object.svg',
        ID_CLASS_OPTIKIT_OPTIKIT_DIAGRAM: 'optikit_diagram.svg',
        IDO_CATALOGUE_BEARING: 'bearings_catalog_selection.svg',
    },
    product_selection: {},
    tool_bar: {},
    system: {
        VIEW_VR: 'view_vr.svg',
        VIEW_AR: 'view_ar.svg',
        VIEW_TEXTURE_ON: 'view_texture_on.svg',
        VIEW_TEXTURE_OFF: 'view_texture_off.svg',
        VIEW_SOLID: 'round-solid.svg',
    },
    object_view: {
        VIEW_VIEWS: 'view.svg',
        VIEW_PERSPECTIVE: 'view_perspective.svg',
        VIEW_ZOOM_TO_FIT: 'zoom_to_fit.svg',
        VIEW_CLIPPING: 'view_clipping_on.svg',
        VIEW_CLIPPING_OFF: 'view_clipping_off.svg',
        VIEW_CLIPPING_HALF: 'view_clipping_50.svg',
        VIEW_CLIPPING_QUARTER: 'view_clipping_75.svg',
        VIEW_CLIPPING_WIREFRAME_ON: 'view_clipping_wireframe_on.svg',
        VIEW_FRONT: 'view_front.svg',
        VIEW_BACK: 'view_back.svg',
        VIEW_LEFT: 'view_left.svg',
        VIEW_RIGHT: 'view_right.svg',
        VIEW_TOP: 'view_top.svg',
        VIEW_BOTTOM: 'view_bottom.svg',
        VIEW_WIREFRAME_ON: 'view_wireframe_on.svg',
        VIEW_WIREFRAME_OFF: 'view_wireframe_off.svg',
        VIEW_LEFT_GRAY: 'view_left_gray.svg',
        VIEW_RIGHT_GRAY: 'view_right_gray.svg',
        VIEW_TOP_GRAY: 'view_top_gray.svg',
        VIEW_BOTTOM_GRAY: 'view_bottom_gray.svg',
        VIEW_FRONT_GRAY: 'view_front_gray.svg',
        VIEW_BACK_GRAY: 'view_back_gray.svg',
        CAMERA: 'camera.svg',
        TWO_D: 'view_window_2d.svg',
        THREE_D: 'view_window_3d.svg',
        PARALLEL: 'view_window_3d-2d.svg',
        THREE_D_NATURAL_MODES: 'view_window_3d-natural-modes.svg',
        VIEW_WINDOW: 'view_window.svg',
        TWO_D_AND_CHART: 'view_window_chart-2d.svg',
        VIEW_HIDE_MEASUREMENT: 'view_hide_measurement.svg',
        VIEW_SHOW_MEASUREMENT: 'view_show_measurement.svg',
    },
};

@Injectable({ providedIn: 'root' })
export class IconMappingService {
    public getIcon(types: string[], context: IconContext): string | undefined {
        const fileName = types.reduce((prev, curr) => {
            const mapped = (mapping[context] as { [key: string]: string })[curr];
            return !!mapped ? mapped : prev;
        }, undefined);
        return !!fileName ? `./assets/icons/${context}/${fileName}` : void 0;
    }
}
