<form [formGroup]="formGroup">
    <div class="content">
        <bx-form-field class="full">
            <label [ngClass]="{ 'disabled': readOnlyActive }"
                >{{ 'SHARED.MODEL.METADATA.NAME.CAPTION' | translate }}
                <input bxInput formControlName="name" [placeholder]="'SHARED.MODEL.METADATA.NAME.PLACEHOLDER' | translate" />
            </label>
        </bx-form-field>

        <fieldset>
            <bx-form-field class="full">
                <label for="project" [ngClass]="{ 'disabled': readOnlyActive }"
                    >{{ 'SHARED.MODEL.METADATA.PROJECT.CAPTION' | translate }}
                    <bx-breadcrumbs
                        id="project"
                        [project]="projectChanged | async"
                        [isReadOnly]="true"
                        [isDisabled]="readOnlyActive"
                    ></bx-breadcrumbs>
                </label>
            </bx-form-field>
        </fieldset>
        <fieldset>
            <bx-form-field class="full">
                <div class="button-bar">
                    <div class="left">
                        <button type="button" class="primary" (click)="onSelect()" [disabled]="readOnlyActive">
                            {{ 'SHARED.MODEL.METADATA.PROJECT.SELECT' | translate }}
                        </button>
                    </div>
                </div>
            </bx-form-field>
        </fieldset>

        <div class="line"></div>

        <bx-form-field class="full">
            <label [ngClass]="{ 'disabled': readOnlyActive }"
                >{{ 'SHARED.MODEL.METADATA.DESCRIPTION.CAPTION' | translate }}
                <textarea
                    class="form-control"
                    bxInput
                    rows="8"
                    formControlName="description"
                    [placeholder]="'SHARED.MODEL.METADATA.DESCRIPTION.PLACEHOLDER' | translate"
                ></textarea>
            </label>
        </bx-form-field>

        <fieldset>
            
            <bx-form-field>
                <label for="tags" [ngClass]="{ 'disabled': readOnlyActive }"
                    >{{ 'SHARED.MODEL.METADATA.TAGS.CAPTION' | translate }}
                    <bx-tags-input
                        id="tags"
                        [disabled]="readOnlyActive"
                        formControlName="tags"
                        maxLength="35"
                        [placeholder]="'SHARED.MODEL.METADATA.TAGS.PLACEHOLDER' | translate"
                    ></bx-tags-input>
                </label>
            </bx-form-field>
        </fieldset>

        <fieldset>
            <bx-form-field>
                <label for="visibility" [ngClass]="{ 'disabled': readOnlyActive }"
                    >{{ 'SHARED.MODEL.METADATA.VISIBILITY.CAPTION' | translate }}
                    <bx-checkbox
                        id="visibility"
                        class="form-control"
                        formControlName="visibility"
                        [caption]="'SHARED.MODEL.METADATA.VISIBILITY.ON_CAPTION' | translate"
                        [offCaption]="'SHARED.MODEL.METADATA.VISIBILITY.OFF_CAPTION' | translate"
                        [asToggle]="true"
                        [onValue]="visibilities.Public"
                        [offValue]="visibilities.Private"
                    ></bx-checkbox>
                </label>
            </bx-form-field>
        </fieldset>
    </div>

    <footer class="footer">
        <div class="button-bar">
            <div class="left">
                <button type="button" class="secondary" (click)="onCancel()">
                    {{ cancelLabel }}
                </button>
            </div>
            <div class="center message-container warning" *ngIf="readOnlyActive">
                <i class="material-icons warning-info">info</i>
                <span> {{ 'SHARED.MODEL.EDIT.LOCK_MESSAGE' | translate }} </span>
            </div>
            <div class="right">
                <button
                    type="button"
                    class="primary"
                    (click)="onConfirm(formGroup)"
                    [disabled]="!(permittedToWrite$ | async) || !formGroup.valid || (editorContext.readOnlyChanged | async)"
                >
                    <span>{{ isEditmode ? ('GLOBALS.SAVE' | translate) : ('GLOBALS.CREATE' | translate) }}</span>
                </button>
            </div>
        </div>
    </footer>
</form>
