import { Injectable, OnDestroy } from '@angular/core';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { map, takeUntil, tap } from 'rxjs/operators';
import { AuthenticationService } from '../auth/authentication.service';
import { WebStorageService } from '../storage/web-storage.service';
import { CURRENT_TENANT_KEY, STRING_BEARINX_ONLINE, Tenant } from './tenant.model';

@Injectable({
    providedIn: 'root',
})
export class TenantService implements OnDestroy {
    private readonly _tenants = new BehaviorSubject<Tenant[]>([]);
    private readonly _tenant = new BehaviorSubject<Tenant | undefined>(undefined);
    private readonly _destroy$ = new Subject<void>();
    private static readonly DEMO_TENANT_NAME = 'Demo';

    public readonly tenants$ = this._tenants.asObservable();
    public readonly tenant$ = this._tenant.asObservable();
    public readonly demoMode$: Observable<boolean>;

    constructor(
        private readonly _webStorageService: WebStorageService,
        private readonly _authService: AuthenticationService,
    ) {
        this._authService.user$
            .pipe(
                map((user) => user.tenants || []),
                map((tenants) => tenants.filter((tenant) => tenant.client === STRING_BEARINX_ONLINE)),
                tap(this._tenants),
                takeUntil(this._destroy$),
            )
            .subscribe((tenants) => this._applyCurrentTenant(tenants));

        this.demoMode$ = this.tenant$.pipe(map((tenant) => tenant?.name === TenantService.DEMO_TENANT_NAME));
    }

    public ngOnDestroy(): void {
        this._destroy$.next();
        this._destroy$.complete();
    }

    public get tenants(): Tenant[] {
        return this._tenants.value;
    }

    public get tenant(): Tenant | undefined {
        return this._tenant.value;
    }

    public getTenant(tenantId: string): Tenant | undefined {
        return this.tenants.find(({ id }) => tenantId === id);
    }

    public setTenant(tenant: Tenant | undefined): void {
        const currentTenant = this._tenant.value;

        if (currentTenant?.id === tenant?.id) {
            return;
        }

        if (!tenant || !this.getTenant(tenant.id)) {
            this._clearTenant();
            return;
        }
        this._tenant.next(tenant);
        this._webStorageService.setValue(CURRENT_TENANT_KEY, tenant.id);
    }

    private _clearTenant(): void {
        this._tenant.next(undefined);
        this._webStorageService.removeValue(CURRENT_TENANT_KEY);
    }

    private _applyCurrentTenant(tenants: Tenant[]) {
        const defaultTenant = tenants.length === 1 ? tenants[0] : undefined;
        const tenantId = this._webStorageService.getValue(CURRENT_TENANT_KEY);

        const tenant = tenants.find(({ id }) => id === tenantId) || defaultTenant;
        this.setTenant(tenant);
    }
}
