import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from '../../modules/auth/auth.guard';
import { NotFoundComponent, NotFoundResolverService, ServerErrorComponent } from '../../modules/error-handling';
import { RoleGuard } from './../../modules/role/role.guard';
import { AppContainerComponent } from './app-container/app-container.component';

const ROUTES: Routes = [
    {
        path: '',
        component: AppContainerComponent,
        canActivate: [AuthGuard.canActivate],
        children: [
            {
                path: '',
                pathMatch: 'full',
                redirectTo: 'home-screen',
            },
            {
                path: 'home-screen',
                loadChildren: () => import('../home-screen/home-screen.module').then((m) => m.HomeScreenModule),
            },
            {
                path: 'settings',
                loadChildren: () => import('../settings/settings.module').then((m) => m.SettingsModule),
            },
            {
                path: 'examples',
                loadChildren: () => import('../example/example.module').then((m) => m.ExampleModule),
            },
            {
                path: 'wizards',
                loadChildren: () => import('../wizard/wizard.module').then((m) => m.WizardModule),
            },
            {
                path: 'project',
                canActivateChild: [RoleGuard.canActivateChild],
                loadChildren: () => import('../project/project.module').then((m) => m.ProjectModule),
            },
            {
                path: 'models',
                loadChildren: () => import('../model/model.module').then((m) => m.ModelModule),
            },
            {
                path: 'editor',
                loadChildren: () => import('../editor/editor.module').then((m) => m.EditorModule),
            },
            {
                path: 'not-found',
                component: NotFoundComponent,
                resolve: {
                    notFoundData: NotFoundResolverService,
                },
            },
        ],
    },
    {
        path: 'server-error',
        component: ServerErrorComponent,
    },
    {
        path: '**',
        redirectTo: 'not-found',
    },
];

@NgModule({
    imports: [
        RouterModule.forRoot(ROUTES, {
            useHash: true,
        }),
    ],
    exports: [RouterModule],
})
export class BearinxRoutingModule {}
