import { Component, ElementRef, EventEmitter, Input, Output, ViewChild, OnDestroy } from '@angular/core';
import { Subject, timer } from 'rxjs';
import { Breadcrumb } from './breadcrumb.model';
import { BreadcrumbsService } from './breadcrumbs.service';
import { Project } from '../../app/project/project.model';
import { takeUntil } from 'rxjs/operators';

@Component({
    selector: 'bx-breadcrumbs',
    templateUrl: './breadcrumbs.component.html',
    styleUrls: ['./breadcrumbs.component.scss'],
})
export class BreadcrumbsComponent implements OnDestroy {
    @ViewChild('breadcrumbContainer', { static: true })
    protected breadcrumbContainer: ElementRef<HTMLDivElement>;

    public breadcrumbs: Breadcrumb[] = [];

    @Input() public isDisabled = false;
    @Input() public isReadOnly: boolean;
    @Input() public set project(currentProject: Project) {
        this.breadcrumbs = this.breadcrumbsService.updateBreadcrumbs(this.breadcrumbs, currentProject);
        this.scrollToEnd();
    }

    @Output()
    public projectChange = new EventEmitter<string>();

    private readonly _destroy$ = new Subject<void>();

    constructor(private readonly breadcrumbsService: BreadcrumbsService) {}

    ngOnDestroy(): void {
        this._destroy$.next();
        this._destroy$.complete();
    }

    public byProjectId(index: number, breadcrumb: Breadcrumb) {
        return `${index}.${breadcrumb.projectId}`;
    }

    public onProjectChange(projectId: string): void {
        if (!this.isReadOnly) {
            this.projectChange.emit(projectId);
        }
    }

    protected async scrollToEnd() {
        if (this.breadcrumbContainer == null) {
            return;
        }

        timer(50)
            .pipe(takeUntil(this._destroy$))
            .subscribe(() => {
                const { nativeElement } = this.breadcrumbContainer;
                const { scrollWidth: left } = nativeElement;
                nativeElement.scrollTo({ left, top: 0 });
            });
    }
}
