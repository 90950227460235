import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { Project } from '../../app/project/project.model';
import { reduce, take } from 'rxjs/operators';
import { ProjectService } from '../../app/project/project.service';

const isProject = (project: any): project is Project => (project as Project)?.id !== undefined;
const isProjectId = (project: any): project is string => typeof project === 'string';

@Injectable({
    providedIn: 'root',
})
export class ProjectSelectionService {
    private readonly _currentlySelected: BehaviorSubject<Project | undefined | null> = new BehaviorSubject(undefined);

    readonly currentlySelected = this._currentlySelected.asObservable();

    constructor(private readonly _projectService: ProjectService) {}

    public selectInitialProject(project?: Project | string | null) {
        if (isProjectId(project)) {
            this._projectService.projects$
                .pipe(
                    take(1),
                    reduce((result, projects) => (result ? result : projects.find((entry) => entry.id === project)), undefined),
                )
                .subscribe((selectedProject) => this._currentlySelected.next(selectedProject));
        } else if (isProject(project)) {
            this._currentlySelected.next(project);
        } else {
            this._currentlySelected.next(project);
        }
    }
}
