<div>
    <bx-icon
        *ngIf="enableBookmark"
        [icon]="bookmarked ? 'star' : 'star_border'"
        width="30px"
        height="30px"
        [ngClass]="bookmarked ? 'bookmark' : 'no-bookmark'"
        (click)="setBookmarkState($event)"
        [title]="title | translate"
    >
    </bx-icon>
    <span (click)="onSelect()" class="subproject-entry-name">{{ name }}</span>
    <ng-container *ngIf="enableContextMenu && permission$ | async as permission">
        <i class="options material-icons overlay-element" [bxContextMenuClick]="contextMenu">more_vert</i>

        <ng-template #contextMenu>
            <bx-context-menu>
                <bx-context-menu-item
                    *ngIf="permission.allowUpdate"
                    icon="create"
                    [caption]="'GLOBALS.RENAME' | translate"
                    (action)="renameItem()"
                ></bx-context-menu-item>
                <bx-context-menu-item
                    *ngIf="permission.allowDelete"
                    icon="delete"
                    [caption]="'GLOBALS.DELETE' | translate"
                    (action)="deleteItem()"
                ></bx-context-menu-item>
                <bx-context-menu-item
                    *ngIf="permission.allowMove"
                    icon="folder_copy"
                    [caption]="'GLOBALS.MOVE' | translate"
                    (action)="moveItem()"
                ></bx-context-menu-item>
            </bx-context-menu>
        </ng-template>
    </ng-container>
</div>
