import { Component, OnDestroy, OnInit } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, ValidatorFn, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { EditorContext } from '../../../app/editor/editor-context.model';
import { ModelApiService } from '../model-api.service';
import { ModelService } from '../model.service';

@Component({
    selector: 'bx-model-delete',
    templateUrl: './model-delete.component.html',
    styleUrls: ['./model-delete.component.scss'],
})
export class ModelDeleteComponent implements OnDestroy {
    public formGroup: FormGroup;
    public modelName: string;

    private _destroy$ = new Subject<void>();

    constructor(
        private readonly _modelApiService: ModelApiService,
        private readonly _formBuilder: FormBuilder,
        private readonly _modelService: ModelService,
        private readonly _router: Router,
        public readonly editorContext: EditorContext,
    ) {
        this.modelName = this._modelService.model!.name;
        this.formGroup = this._formBuilder.group({
            name: ['', [Validators.required, ModelDeleteComponent._sameNameValidator(this.modelName)]],
        });
    }

    private static _sameNameValidator(name: string): ValidatorFn {
        return (control: AbstractControl): { [key: string]: any } | null => {
            const enteredName = control!.value;
            return enteredName !== name ? { sameName: { value: enteredName } } : null;
        };
    }

    public ngOnDestroy(): void {
        this._destroy$.next();
        this._destroy$.complete();
    }

    public confirm(): void {
        const model = this._modelService.model!;
        this._modelApiService
            .delete(model.id)
            .pipe(takeUntil(this._destroy$))
            .subscribe(
                () => this._router.navigate(['home-screen']),
                (err) => alert(err),
            );
    }

    public onCancel(): void {
        const model = this._modelService.model!;
        this._router.navigate(['editor', model.tenantId!, model.groupId!, model.id, 'edit']);
    }
}
