import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { Notification, NotificationMutator, NotificationProgress, NotificationType } from './notification.model';

@Injectable({ providedIn: 'root' })
export class NotificationsService {
    private static _COUNTER = 0;
    private _notifications = new Map<number, Notification>();
    private _notifications$ = new Subject<Notification[]>();

    public get notifications$(): Observable<Notification[]> {
        return this._notifications$;
    }

    /**
     * Create a notification with a unique ID
     * @param type
     * @param caption
     * @param progress
     */
    public create(type: NotificationType, caption: string, progress?: NotificationProgress): NotificationMutator {
        const id = NotificationsService._COUNTER++;
        const seen = false;
        return new NotificationMutator({ id, type, caption, seen, progress });
    }

    public add(notification: Notification): void {
        this._notifications.set(notification.id, notification);
        this._emitNotifications();
    }

    public get(id: number): Notification | undefined {
        return this._notifications.get(id);
    }

    public update(id: number, notification: Notification): void {
        if (!this._notifications.has(id)) {
            throw new Error(`No notification with ${id}`);
        }
        this._notifications.set(id, notification);
        this._emitNotifications();
    }

    public dismiss(id: number): void {
        if (this._notifications.has(id)) {
            this._notifications.delete(id);
            this._emitNotifications();
        }
    }

    public seenAll(): void {
        this._notificationArray.forEach((notification) => {
            const id = notification.id;
            this._notifications.set(id, new NotificationMutator(notification).seen().get());
        });
        this._emitNotifications();
    }

    private _emitNotifications(): void {
        this._notifications$.next(this._notificationArray);
    }

    private get _notificationArray(): Notification[] {
        return Array.from(this._notifications.values());
    }
}
