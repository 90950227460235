import { CameraType } from '@webfoundation/viewer';
import { ClippingPlaneType } from './clipping-plane-type.model';
import { ExampleSettings } from './example-settings.model';
import { Scene3DSettings } from './scene-3d-settings.model';
import { WarningSettings } from './warning-settings.model';
import { DefaultViewSettings } from './default-view.model';
import { SceneViewIcon } from 'src/app/editor/scene-view-icon.model';

export interface Settings extends IObjectKeys {
    language: string;
    unitSet: string;
    locale: string;
    warnings: Partial<WarningSettings>;
    scene3d: Partial<Scene3DSettings>;
    examples?: ExampleSettings;
    defaultView: Partial<DefaultViewSettings>;
}

export interface IObjectKeys {
    [key: string]:
        | string
        | ExampleSettings
        | undefined
        | Partial<Scene3DSettings>
        | Partial<WarningSettings>
        | Partial<DefaultViewSettings>;
}

export const DEFAULT_SETTINGS_VALUE: Settings = {
    language: 'LANGUAGE_ENGLISH',
    unitSet: 'ID_UNIT_SET_SI',
    locale: 'en-US',
    warnings: {
        delLoadCases: false,
        delShafts: false,
        delSystems: false,
        delLinAxes: false,
    },
    scene3d: {
        rotateAroundSelection: true,
        zoomToMousePosition: false,
        clippingPlane: ClippingPlaneType.XY,
        flipClippingPlane: false,
        textured: false,
        cameraType: CameraType.Perspective,
    },
    defaultView: { view: SceneViewIcon.Parallel },
};

export const DEFAULT_LANGUAGES = {
    english: {
        value: 'LANGUAGE_ENGLISH',
        caption: 'English',
    },
    german: {
        value: 'LANGUAGE_GERMAN',
        caption: 'German',
    },
};

export const DEFAULT_UNITSET = {
    si: {
        value: 'ID_UNIT_SET_SI',
        caption: 'SI units',
    },
    fps: {
        value: 'ID_UNIT_SET_FPS',
        caption: 'FPS units',
    },
};
