import { Injectable, Injector } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { AuthenticationService } from '../auth/authentication.service';
import { Group } from '../group/group.model';
import { GroupService } from '../group/group.service';

export function checkRole(role: string, roles: string[]): boolean {
    const roleParts = role.split('*');
    const wildcardRole = roleParts.length === 2 ? roleParts[0] : null;
    return roles.some((existingRole) => (wildcardRole ? existingRole.startsWith(wildcardRole) : existingRole === role));
}

@Injectable({ providedIn: 'root' })
export class RoleService {
    constructor(
        private readonly _groupService: GroupService,
        private readonly _injector: Injector,
    ) {}

    public userRoles(): string[] {
        return this._groupService.group?.roles ?? [];
    }

    public userHasRoles(roles: string[]): Observable<boolean> {
        return this._groupService.group$.pipe(map((group) => !!group && roles.every((role) => checkRole(role, group.roles))));
    }

    public getGroupsWithRole(roles: string[]): Observable<Group[]> {
        return this._injector.get(AuthenticationService).user$.pipe(
            map(({ groups }) => groups ?? []),
            map((groups) => groups.filter((group) => roles.every((role) => checkRole(role, group.roles)))),
        );
    }
}
