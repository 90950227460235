<bx-title-bar class="preserve-whitespace"
    ><h4 caption>{{ 'SHARED.MODEL.EDIT.TITLE' | translate: modelService.model }}</h4></bx-title-bar
>

<div class="content">
    <bx-model-metadata-form
        #model_metadata_form
        [model]="modelService.model"
        [cancelLabel]="'GLOBALS.CANCEL' | translate"
        (confirm)="onConfirm($event)"
        (cancel)="onCancel()"
    ></bx-model-metadata-form>
</div>
