import { CommonModule } from '@angular/common';
import { APP_INITIALIZER, ModuleWithProviders, NgModule } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { CalculationService } from '../calculation/calculation.service';
import { PromptModule } from '../prompt/prompt.module';
import { SettingsService } from '../settings/settings.service';
import { AuthenticationService } from './authentication.service';
import { ConfirmLogoutPrompt } from './confirm-logout/confirm-logout.prompt';
import { ConfirmReLoginPrompt } from './confirm-re-login/confirm-re-login.prompt';
import { AwaitLoginPrompt } from './login-wait-blocker/await-login.prompt';
import { InitializationStatusService } from '../initialization/initialization-status.service';

const WAIT_FOR_CONNECTION = 20_000;

export function AuthenticationAppInitializer(
    authService: AuthenticationService,
    calculationService: CalculationService,
    settingService: SettingsService,
    initializationStatusService: InitializationStatusService,
): () => Promise<any> {
    return async () => {
        try {
            await authService.authenticate();
            await authService.setupSilentRefresh();
            await Promise.all([authService.loadUser(), settingService.prefetch()]);

            settingService.start();
            await calculationService.start();
            initializationStatusService.setAuthModuleInitStatus(true);
        } catch (err) {
            initializationStatusService.setAuthModuleInitStatus(false);
            console.error('AuthenticationAppInitializer failed. Initialization status set to false.', err);
        }
    };
}

@NgModule({
    imports: [CommonModule, TranslateModule, PromptModule],
    declarations: [ConfirmReLoginPrompt, AwaitLoginPrompt, ConfirmLogoutPrompt],
})
export class AuthModule {
    public static forRoot(): ModuleWithProviders<AuthModule> {
        return {
            ngModule: AuthModule,
            providers: [
                {
                    provide: APP_INITIALIZER,
                    multi: true,
                    useFactory: AuthenticationAppInitializer,
                    deps: [AuthenticationService, CalculationService, SettingsService, InitializationStatusService],
                },
            ],
        };
    }
}
