import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MultiSelectOption } from './multi-select-option';

@Component({
    selector: 'bx-multi-select-overlay',
    templateUrl: 'multi-select-overlay.component.html',
    styleUrls: ['multi-select-overlay.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MultiSelectOverlayComponent implements OnInit {
    @Input() public options: MultiSelectOption[] = [];
    @Input() public selectedIds: any[] = [];
    @Output() public update = new EventEmitter<any[]>();

    selected: Set<any>;

    public ngOnInit(): void {
        this.selected = new Set<any>(this.selectedIds);
    }

    public toggle(id: any): void {
        if (this.selected.has(id)) {
            this.selected.delete(id);
        } else {
            this.selected.add(id);
        }

        this.update.emit(Array.from(this.selected));
    }
}
