import { takeUntil } from 'rxjs/operators';
import { ModelApiService } from './../../modules/model/model-api.service';
import { Injectable, OnDestroy } from '@angular/core';
import { BehaviorSubject, Observable, ReplaySubject, Subject } from 'rxjs';
import { CalculationContext } from './calculation-context.model';
import { ClippingType, CurrentObjectProviderService } from '@webfoundation/viewer';
import { ViewIcon } from './view-icon.model';
import { SceneViewIcon } from './scene-view-icon.model';

@Injectable()
export class EditorContext implements OnDestroy {
    private readonly _calculationContextChanged = new ReplaySubject<CalculationContext | undefined>(1);
    public readonly calculationContextChanged = this._calculationContextChanged;

    private _calculationContext: CalculationContext | undefined;

    private readonly _filteredObjectIds = new ReplaySubject<string[] | null>(1);
    private readonly _vrModeEntered = new Subject<void>();
    private readonly _calculationInProgressChanged = new BehaviorSubject<boolean>(false);
    private readonly _readOnlyChanged = new BehaviorSubject<boolean>(true);
    private readonly _currentSceneViewIcon = new BehaviorSubject<SceneViewIcon>(SceneViewIcon.Parallel);

    private readonly _activeView$ = new BehaviorSubject<ViewIcon>(ViewIcon.Perspective);
    public readonly activeView$ = this._activeView$;

    private readonly _activeClipping$ = new BehaviorSubject<ClippingType>(ClippingType.Half);
    public readonly activeClipping$ = this._activeClipping$.asObservable();

    private readonly _activeMeasurements$ = new BehaviorSubject<boolean>(true);
    public readonly activeMeasurements$ = this._activeMeasurements$.asObservable();

    private readonly _activeCamera$ = new BehaviorSubject<string>('');
    public readonly activeCamera$ = this._activeCamera$.asObservable();

    private readonly _preselectedObjectIds = new Subject<string[]>();
    public readonly preselectedObjectIds$ = this._preselectedObjectIds.asObservable();

    private readonly _objectIdsSelected = new BehaviorSubject<string[]>([]);
    public readonly selectedObjectIds$ = this._objectIdsSelected.asObservable();

    private _currentObjectId = new Map<string, BehaviorSubject<string>>();

    private readonly _destroy$ = new Subject<void>();

    constructor(private readonly _modelApiService: ModelApiService) {
        this._modelApiService.modelUpdated$.pipe(takeUntil(this._destroy$)).subscribe(() => this._calculationContextChanged.next(void 0));
    }

    public get calculationContext(): CalculationContext | undefined {
        return this._calculationContext;
    }

    public set calculationContext(value: CalculationContext | undefined) {
        this._calculationContext = value;
        this._calculationContextChanged.next(value);
    }

    public get selectedObjectIds(): string[] {
        return this._objectIdsSelected.value;
    }

    public get vrModeEntered(): Observable<void> {
        return this._vrModeEntered;
    }

    public get filteredObjectIds(): Observable<string[] | null> {
        return this._filteredObjectIds;
    }

    public preselectObjectIds(objectIds: string[]): void {
        this._preselectedObjectIds.next(objectIds);
    }

    public selectObjectIds(objectIds: string[]): void {
        this._objectIdsSelected.next(objectIds);
    }

    public setFilteredObjectIds(objectIds: string[] | null): void {
        this._filteredObjectIds.next(objectIds);
    }

    public enterVr(): void {
        this._vrModeEntered.next();
    }

    public enterAr(): void {
        this._vrModeEntered.next();
    }

    public set calculationInProgress(value: boolean) {
        this._calculationInProgressChanged.next(value);
    }

    public get calculationInProgressChanged(): Observable<boolean> {
        return this._calculationInProgressChanged;
    }

    public set readOnly(value: boolean) {
        this._readOnlyChanged.next(value);
    }

    public get readOnlyChanged(): Observable<boolean> {
        return this._readOnlyChanged;
    }

    public set currentSceneViewIcon(sceneViewIcon: SceneViewIcon) {
        this._currentSceneViewIcon.next(sceneViewIcon);
    }

    public get currentSceneViewIconChanged(): Observable<SceneViewIcon> {
        return this._currentSceneViewIcon;
    }

    public switchView(icon = ViewIcon.Perspective): void {
        this._activeView$.next(icon);
    }

    public switchClipping(clippingIcon = ClippingType.Half): void {
        this._activeClipping$.next(clippingIcon);
    }

    public toggleMeasurements(): void {
        this._activeMeasurements$.next(!this._activeMeasurements$.value);
    }

    public switchCamera(camera: string): void {
        this._activeCamera$.next(camera);
    }

    public resetView(): void {
        this.switchView();
        this.switchClipping();
        this.switchCamera('');
    }

    public get loadCaseId$(): Observable<string> {
        return this._getLoadCaseSubject().asObservable();
    }

    public get loadCaseId(): string {
        return this._getLoadCaseSubject().value;
    }

    public setLoadCaseId(id?: string): void {
        this._getLoadCaseSubject().next(id || '');
    }

    public get shaftId$(): Observable<string> {
        return this._getShaftSubject().asObservable();
    }

    public get shaftId(): string {
        return this._getShaftSubject().value;
    }

    public setShaftId(id?: string): void {
        this._getShaftSubject().next(id || '');
    }

    public getCurrentObjectIds(): string[] {
        const ids: string[] = [];
        Array.from(this._currentObjectId.keys()).forEach((key) => ids.push(this._currentObjectId.get(key)!.value));
        return ids;
    }

    public isCurrentObject(id: string): boolean {
        return Array.from(this._currentObjectId.keys()).some((key) => this._currentObjectId.get(key)!.value === id);
    }

    private _getLoadCaseSubject(): BehaviorSubject<string> {
        return this._getCurrentObjectSubject(CurrentObjectProviderService.LoadCase);
    }

    private _getShaftSubject(): BehaviorSubject<string> {
        return this._getCurrentObjectSubject(CurrentObjectProviderService.Shaft);
    }

    private _getCurrentObjectSubject(childList: string): BehaviorSubject<string> {
        let subject = this._currentObjectId.get(childList);

        if (!subject) {
            subject = new BehaviorSubject<string>('');
            this._currentObjectId.set(childList, subject);
        }
        return subject;
    }

    public ngOnDestroy() {
        this._destroy$.next();
        this._destroy$.complete();
    }
}
