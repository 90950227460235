import { Injectable } from '@angular/core';
import { Languages } from 'src/app/shared/constants/languages';

// declare OneTrust as a global variable
declare let OneTrust: any;

@Injectable({
    providedIn: 'root',
})
export class OneTrustService {
    constructor() {}

    public oneTrustErrorOccurred = false;

    loadOneTrust(oneTrustId: string, language: string): void {
        const head = document.getElementsByTagName('head')[0];

        // remove existing OneTrust script if it exists
        const existingScript = document.getElementById('one-trust-script');
        if (existingScript) {
            existingScript.remove();
        }
        const currentLang = this._getCurrentLanguage(language);

        const oneTrustScript = document.createElement('script') as HTMLScriptElement;
        oneTrustScript.id = 'one-trust-script';
        oneTrustScript.src = 'https://cdn.cookielaw.org/scripttemplates/otSDKStub.js';
        oneTrustScript.setAttribute('data-document-language', 'true');
        oneTrustScript.type = 'text/javascript';
        oneTrustScript.setAttribute('charset', 'UTF-8');
        oneTrustScript.setAttribute('data-domain-script', oneTrustId);
        oneTrustScript.setAttribute('data-language', currentLang);

        head.insertBefore(oneTrustScript, head.firstChild);

        const oneTrustFunction = document.createElement('script') as HTMLScriptElement;
        oneTrustFunction.type = 'text/javascript';
        oneTrustFunction.textContent = 'function OptanonWrapper(){ }';

        head.appendChild(oneTrustFunction);

        oneTrustScript.onload = () => {
            if (typeof OneTrust !== 'undefined' && OneTrust.OnConsentChanged) {
                const event = new CustomEvent('oneTrustLoaded');
                OneTrust.OnConsentChanged(event);
            }
        };
        oneTrustScript.onerror = () => {
            this.oneTrustErrorOccurred = true;
        };
    }

    private _getCurrentLanguage(language: string): string {
        // we need it, because oneTrust accepts in this way (e.g en, es, pl, etc.)
        let currentLang = '';
        if (language === 'LANGUAGE_ENGLISH') {
            currentLang = Languages.English;
        } else if (language === 'LANGUAGE_GERMAN') {
            currentLang = Languages.German;
        } else {
            throw Error('Unknown language');
        }
        return currentLang;
    }

    translateBanner(language: string, reload?: boolean): void {
        const currentLang = this._getCurrentLanguage(language);
        if (typeof OneTrust !== 'undefined') {
            OneTrust.changeLanguage(currentLang);
            if (reload) {
                OneTrust.LoadBanner();
            }
        }
    }
}
