import { Inject, Injectable, Optional } from '@angular/core';
import { BaseExporter } from './base.exporter';

@Injectable({
    providedIn: 'root',
    deps: [BaseExporter],
})
export class ExportService {
    public constructor(@Inject(BaseExporter) private readonly _exporters: BaseExporter[]) {}

    /**
     * Gets a list of available exporter names
     * @returns Returns a list of available exporter names
     */
    public getExporterNames(): string[] {
        return this._exporters.map((exporter) => exporter.getExporterName());
    }

    /**
     * Gets a list of available exporters
     * @returns Returns a list of available exporters
     */
    public getExporters(): BaseExporter[] {
        return this._exporters;
    }

    /**
     * Gets a list of supported exporters
     * @param exporterName This is the name of the requested exporter
     * @returns Returns the requested export instance or undefined if not found
     */
    public getExporter(exporterName: string): BaseExporter {
        return this._exporters.find((exporter) => exporter.getExporterName() === exporterName)!;
    }
}
