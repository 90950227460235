<bx-title-bar><h4 caption translate>SHARED.MODEL.DELETE.TITLE</h4></bx-title-bar>
<form [formGroup]="formGroup">
    <div class="content">
        <div class="model-name" [hidden]="editorContext.readOnlyChanged | async">
            <label class="preserve-whitespace" data-cy="model-name-confirmation-text">{{ 'SHARED.MODEL.DELETE.TEXT' | translate: { modelName: modelName } }}</label>
            <bx-form-field class="full">
                <label>{{ 'SHARED.MODEL.METADATA.NAME.CAPTION' | translate }}</label>
                <input bxInput formControlName="name" />
            </bx-form-field>
        </div>
        <bx-form-field class="full" *ngIf="editorContext.readOnlyChanged | async">
            <p class="warning" translate>SHARED.MODEL.DELETE.LOCK</p>
        </bx-form-field>
    </div>
    <footer class="footer">
        <div class="button-bar">
            <div class="left">
                <button type="button" class="secondary" (click)="onCancel()">
                    {{ 'GLOBALS.CANCEL' | translate }}
                </button>
            </div>
            <div class="right">
                <button
                    type="button"
                    class="primary"
                    [disabled]="!formGroup.valid || (editorContext.readOnlyChanged | async)"
                    (click)="confirm()"
                >
                    {{ 'GLOBALS.DELETE' | translate }}
                </button>
            </div>
        </div>
    </footer>
</form>
