import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { SettingsService } from '../settings.service';
import { Subject, of } from 'rxjs';
import { mergeMap, delay, switchMap, map, scan, filter, takeUntil, take } from 'rxjs/operators';
import { SceneViewIcon } from 'src/app/editor/scene-view-icon.model';

@Component({
    selector: 'bx-default-view-settings',
    templateUrl: './default-view-settings.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DefaultViewSettingsComponent implements OnInit, OnDestroy {
    private readonly _destroy$ = new Subject();

    public readonly form = this._formBuilder.group({
        view: [SceneViewIcon.Parallel, Validators.required],
    });

    public defaultView$ = of(this._settingsService.defaultView).pipe(
        mergeMap((types) => types),
        delay(0),
        switchMap((type) =>
            this._translateService.get(`SHARED.SETTINGS.DEFAULT_VIEW.DEFAULT_VIEW_${type}`).pipe(
                map((translation) => ({
                    id: type,
                    caption: translation,
                })),
            ),
        ),
        scan((acc, value) => [...acc, value], []),
        map((types) => (types?.length > 0 ? types.slice(0, 3) : undefined)),
    );

    constructor(
        private readonly _formBuilder: FormBuilder,
        private readonly _settingsService: SettingsService,
        private readonly _translateService: TranslateService,
        private readonly _cd: ChangeDetectorRef,
    ) {}

    public ngOnInit(): void {
        this._settingsService?.defaultViewSettings$
            .pipe(
                takeUntil(this._destroy$),
                filter((defaultView) => !!defaultView),
            )
            .subscribe((defaultView) => {
                this.form.patchValue(defaultView!);
                this._cd.markForCheck();
            });
    }

    public save(): void {
        if (!this.form.invalid && !this.form.pristine) {
            this.form.disable();

            this._settingsService
                .saveSettings({ defaultView: { view: this.form.value.view as SceneViewIcon.Parallel | undefined } })
                .pipe(take(1))
                .subscribe(() => {
                    this.form.enable();
                });
        }
    }

    public ngOnDestroy(): void {
        this._destroy$.next();
        this._destroy$.complete();
    }
}
