<div #breadcrumbContainer>
    <span
        class="preserve-whitespace"
        [class.readonly]="isReadOnly"
        [ngClass]="{
            disabled: isDisabled,
        }"
        *ngFor="let breadcrumb of breadcrumbs; trackBy: byProjectId"
        (click)="onProjectChange(breadcrumb.projectId)"
    >
        {{ breadcrumb.label }}
    </span>
</div>
