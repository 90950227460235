<bx-title-bar><h4 caption translate>SHARED.MODEL.EXPORT.TITLE</h4></bx-title-bar>
<form [formGroup]="export">
    <div class="content">
        <bx-form-field>
            <label>{{ 'SHARED.MODEL.EXPORT.FORMAT.CAPTION' | translate }}</label>
            <bx-combo-box
                formControlName="format"
                [placeholder]="'SHARED.MODEL.EXPORT.FORMAT.PLACEHOLDER' | translate"
                [options]="formats | sortBy: 'caption'"
                *ngIf="formats$ | async as formats"
            ></bx-combo-box>
        </bx-form-field>
    </div>
    <footer class="footer">
        <div class="button-bar">
            <div class="left">
                <button type="button" class="secondary" (click)="onCancel()">
                    {{ 'GLOBALS.CANCEL' | translate }}
                </button>
            </div>
            <div class="right">
                <!--<a [attr.href]="exportUrl$ | async" download>-->
                <button type="button" class="primary" [disabled]="!exporter" (click)="exportModel()">
                    {{ 'GLOBALS.EXPORT' | translate }}
                </button>
                <!--</a>-->
            </div>
        </div>
    </footer>
</form>
