import { Injectable } from '@angular/core';
import { BehaviorSubject, combineLatest, Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class InitializationStatusService {
    private readonly dataModelModuleInitStatusSubject = new BehaviorSubject<boolean>(false);
    private readonly authModuleInitStatusSubject = new BehaviorSubject<boolean>(false);

    readonly dataModelModuleInitStatus$: Observable<boolean> = this.dataModelModuleInitStatusSubject.asObservable();
    readonly authModuleInitStatus$: Observable<boolean> = this.authModuleInitStatusSubject.asObservable();

    readonly initializationStatus$: Observable<boolean> = combineLatest([this.dataModelModuleInitStatus$, this.authModuleInitStatus$]).pipe(
        map(([dataModelModuleInitStatus, authModuleInitStatus]) => dataModelModuleInitStatus && authModuleInitStatus),
    );

    setDataModelModuleInitStatus(isInitializedSuccessfully: boolean) {
        this.dataModelModuleInitStatusSubject.next(isInitializedSuccessfully);
    }

    setAuthModuleInitStatus(isInitializedSuccessfully: boolean) {
        this.authModuleInitStatusSubject.next(isInitializedSuccessfully);
    }
}
