import { Pipe, PipeTransform } from '@angular/core';
import { GroupService } from './group.service';

@Pipe({
    name: 'groupName',
})
export class GroupNamePipe implements PipeTransform {
    constructor(private readonly _groupService: GroupService) {}

    transform(groupId?: string): any {
        return this._groupService.getGroup(groupId || '')?.name || '';
    }
}
