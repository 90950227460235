import { Directive, ElementRef, EventEmitter, OnDestroy, OnInit, Output } from '@angular/core';

@Directive({
    selector: '[bxElementResized]',
})
export class ElementResizedDirective implements OnInit, OnDestroy {
    @Output()
    public bxElementResized = new EventEmitter<void>();

    private _resizeObserver = {
        observe: (_element: HTMLElement): void => undefined,
        unobserve: (_element: HTMLElement): void => undefined,
    };

    constructor(private readonly _elementRef: ElementRef) {
        if ('ResizeObserver' in window) {
            this._resizeObserver = new window['ResizeObserver'](() => this.bxElementResized.emit());
        }
    }

    public ngOnInit() {
        this._resizeObserver.observe(this._elementRef.nativeElement);
    }

    public ngOnDestroy() {
        this._resizeObserver.unobserve(this._elementRef.nativeElement);
    }
}
