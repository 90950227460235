import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Inject, LOCALE_ID, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { Subject } from 'rxjs';
import { finalize, map, mapTo, startWith, takeUntil } from 'rxjs/operators';
import { WindowRef } from '../../util/window-ref';
import { SettingsService } from '../settings.service';
import { InitializationStatusService } from 'src/modules/initialization/initialization-status.service';
import { DEFAULT_LANGUAGES, DEFAULT_UNITSET } from '../settings.model';

@Component({
    selector: 'bx-app-settings',
    templateUrl: './app-settings.component.html',
    styleUrls: ['./app-settings.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AppSettingsComponent implements OnInit, OnDestroy {
    public isInitializedSuccessfully = false;
    public readonly now = new Date();
    public form: FormGroup;
    public localeMismatch = false;
    public languages: Array<{ id: string; caption: string }>;
    public unitSets: Array<{ id: string; caption: string }>;

    public locales$ = this._translateService.onLangChange.pipe(
        startWith(1),
        mapTo(this._settingsService.locales),
        map((ids) =>
            ids.map((id) => {
                const caption = this._translateService.instant(`SHARED.SETTINGS.APP.LOCALES.${id}`);
                return { id, caption };
            }),
        ),
    );

    private readonly _destroy$ = new Subject();

    constructor(
        @Inject(LOCALE_ID) private readonly _locale: string,
        private readonly _formBuilder: FormBuilder,
        private readonly _settingsService: SettingsService,
        private readonly _cd: ChangeDetectorRef,
        private readonly _translateService: TranslateService,
        private readonly _windowRef: WindowRef,
        private readonly _initializationStatusService: InitializationStatusService,
    ) {}

    public ngOnInit(): void {
        this.form = this._formBuilder.group({
            language: ['', Validators.required],
            unitSet: ['', Validators.required],
            locale: ['', Validators.required],
        });

        this._initializationStatusService.initializationStatus$.pipe(takeUntil(this._destroy$)).subscribe((status) => {
            this.isInitializedSuccessfully = status;
            this.initializeLanguagesAndUnitSets();
        });

        this._settingsService.settings$.pipe(takeUntil(this._destroy$)).subscribe(({ language, unitSet, locale }) => {
            this.form.setValue({ language, unitSet, locale });
            // this._cd.markForCheck();
        });
    }

    public ngOnDestroy(): void {
        this._destroy$.next();
        this._destroy$.complete();
    }

    public save(): void {
        if (this.form.invalid || this.form.pristine || this.form.disabled) {
            return;
        }

        this.form.disable();
        this._settingsService
            .saveSettings(this.form.value)
            .pipe(
                finalize(() => {
                    this.form.enable();
                    this._cd.markForCheck();
                }),
                takeUntil(this._destroy$),
            )
            .subscribe(() => {
                const { locale } = this.form.value;
                this.localeMismatch = locale !== this._locale;
            });
    }

    public reload(): void {
        this._windowRef.nativeWindow.location.reload();
    }

    private initializeLanguagesAndUnitSets() {
        this.languages = this.isInitializedSuccessfully
            ? this._settingsService.languages.map((language) => ({
                  id: language.item,
                  caption: language.localization.text,
              }))
            : this.getDefaultLanguages();

        this.unitSets = this.isInitializedSuccessfully
            ? this._settingsService.unitSets.map((unitSet) => ({
                  id: unitSet.item,
                  caption: unitSet.localization.text,
              }))
            : this.getDefaultUnitSets();
    }

    private getDefaultLanguages() {
        return [
            { id: DEFAULT_LANGUAGES.english.value, caption: DEFAULT_LANGUAGES.english.caption },
            { id: DEFAULT_LANGUAGES.german.value, caption: DEFAULT_LANGUAGES.german.caption },
        ];
    }

    private getDefaultUnitSets() {
        return [
            { id: DEFAULT_UNITSET.si.value, caption: DEFAULT_UNITSET.si.caption },
            { id: DEFAULT_UNITSET.fps.value, caption: DEFAULT_UNITSET.si.caption },
        ];
    }
}
