<input
    bxInput
    type="text"
    [placeholder]="placeholder"
    (keyup.enter)="onConfirm($event.target)"
    (blur)="onBlur()"
    [disabled]="isDisabled"
    [attr.maxlength]="maxLength"
/>
<div class="tag-list">
    <div class="tag" *ngFor="let tag of tags">
        {{ tag }}
        <button (click)="onRemoveTag(tag)" type="button">
            <i class="material-icons">close</i>
        </button>
    </div>
</div>
