import { APP_INITIALIZER, ModuleWithProviders, NgModule } from '@angular/core';
import { DataModelService } from './data-model.service';
import { InitializationStatusService } from '../initialization/initialization-status.service';

export function DataModelServiceFactory(
    dataModelService: DataModelService,
    initializationStatusService: InitializationStatusService,
): () => Promise<any> {
    return () =>
        dataModelService
            .prefetch()
            .then(() => {
                initializationStatusService.setDataModelModuleInitStatus(true);
            })
            .catch(() => {
                initializationStatusService.setDataModelModuleInitStatus(false);
                console.error('DataModelService prefetch failed. Initialization status set to false.');
            });
}

@NgModule()
export class DataModelModule {
    public static forRoot(): ModuleWithProviders<DataModelModule> {
        return {
            ngModule: DataModelModule,
            providers: [
                {
                    provide: APP_INITIALIZER,
                    multi: true,
                    useFactory: DataModelServiceFactory,
                    deps: [DataModelService, InitializationStatusService],
                },
            ],
        };
    }
}
