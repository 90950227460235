import { Type } from '@angular/core';
import { DialogButton } from './dialog.preset';
import { ModalContainerComponent } from '../components/modal-container/modal-container.component';

export class ModalContext {
    constructor(
        public content: Type<any>,
        public title: string,
        public buttons: DialogButton[],
    ) {}

    public container: ModalContainerComponent;
}
