import { TranslateService } from '@ngx-translate/core';
import { DataModel } from './data-model.model';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { forkJoin, Observable } from 'rxjs';
import { catchError, tap, map } from 'rxjs/operators';
import { DataModelType } from './data-model-type.model';
import {
    SchaefflerBearingType,
    SchaefflerInstallationType,
    SchaefflerLanguage,
    SchaefflerMethod,
    SchaefflerObject,
    SchaefflerProductFeature,
    SchaefflerMaterialCombination,
    SchaefflerUnit,
    SchaefflerUnitSet,
} from './schaeffler-models.model';
import { ServerSideErrorHandlerService } from '../util/server-side-error-handler.service';
import { loadAppConfig } from '../util/util';

/**
 * TODO persist received data offline and implement caching
 */
@Injectable({ providedIn: 'root' })
export class DataModelService {
    public appConfig = loadAppConfig();

    private readonly _baseUrl = `${this.appConfig.baseUrl}${this.appConfig.apiVersion}`;

    private static readonly _MODEL_TYPES = [
        { type: DataModelType.Languages, endpoint: 'DataModel/model/languages' },
        { type: DataModelType.Methods, endpoint: 'DataModel/model/methods' },
        { type: DataModelType.ObjectTypes, endpoint: 'DataModel/model/objectTypes' },
        { type: DataModelType.Units, endpoint: 'DataModel/model/units' },
        { type: DataModelType.UnitSets, endpoint: 'DataModel/model/unitsets' },
    ];

    private static readonly _PRODUCT_TYPES = [
        {
            type: DataModelType.BearingTypes,
            endpoint: (bearinxObjectTypes?: string[]) => `products/bearinxTypes/?${DataModelService._getCategory(bearinxObjectTypes)}`,
        },
        {
            type: DataModelType.InstallationTypes,
            endpoint: (bearinxObjectTypes?: string[]) =>
                `products/installationTypes/?${DataModelService._getTypeQuery(bearinxObjectTypes)}`,
        },
        {
            type: DataModelType.ProductFeatures,
            endpoint: (bearinxObjectTypes?: string[]) => `products/features/?${DataModelService._getTypeQuery(bearinxObjectTypes)}`,
        },
        {
            type: DataModelType.MaterialCombinations,
            endpoint: (bearinxObjectTypes?: string[]) =>
                `products/materialCombinations/?${DataModelService._getTypeQuery(bearinxObjectTypes)}`,
        },
    ];

    private _dataModels = new Map<DataModelType, any>();

    constructor(
        private readonly _httpClient: HttpClient,
        private readonly _translateService: TranslateService,
        private readonly _errorHandler: ServerSideErrorHandlerService,
    ) {}

    private static _getTypeQuery(bearinxObjectTypes?: string[]): string {
        return bearinxObjectTypes && bearinxObjectTypes.length > 0
            ? bearinxObjectTypes.map((bot) => `type=${encodeURIComponent(bot)}`).join('&')
            : '';
    }

    public static isSlidingBearing(bearinxObjectType?: string): boolean {
        return (
            bearinxObjectType === 'IDO_CYLINDRICAL_PLAIN_BUSH' ||
            bearinxObjectType === 'IDO_RADIAL_SPHERICAL_PLAIN_BEARING' ||
            bearinxObjectType === 'IDO_ANGULAR_SPHERICAL_PLAIN_BEARING' ||
            bearinxObjectType === 'IDO_THRUST_WASHER'
        );
    }

    private static _getCategory(bearinxObjectTypes?: string[]): string {
        let category = 'all';

        if (!!bearinxObjectTypes && bearinxObjectTypes.length > 0) {
            category = DataModelService.isSlidingBearing(bearinxObjectTypes[0]) ? 'sliding' : 'rotative';
        }
        return `category=${category}`;
    }

    public prefetch(): Promise<any> {
        const requests = DataModelService._MODEL_TYPES.map(({ type, endpoint }) =>
            this._httpClient.get(`${this._baseUrl}${endpoint}`).pipe(tap((data) => this._onReceive(type, data))),
        );

        /**
         * TODO: Wire with {@link MetadataService}
         **/
        return forkJoin(requests).toPromise();
    }

    public getModel(type: DataModelType.Languages): SchaefflerLanguage[] | undefined;
    public getModel(type: DataModelType.Methods): SchaefflerMethod[] | undefined;
    public getModel(type: DataModelType.ObjectTypes): SchaefflerObject[] | undefined;
    public getModel(type: DataModelType.Units): SchaefflerUnit[] | undefined;
    public getModel(type: DataModelType.UnitSets): SchaefflerUnitSet[] | undefined;
    public getModel(type: DataModelType.BearingTypes, bearinxObjectType: string[]): Observable<SchaefflerBearingType[]>;
    public getModel(type: DataModelType.InstallationTypes, bearinxObjectType: string[]): Observable<SchaefflerInstallationType[]>;
    public getModel(type: DataModelType.ProductFeatures, bearinxObjectType: string[]): Observable<SchaefflerProductFeature[]>;
    public getModel(type: DataModelType.MaterialCombinations, bearinxObjectType: string[]): Observable<SchaefflerMaterialCombination[]>;
    public getModel(type: DataModelType, bearinxObjectTypes?: string[]): any | undefined {
        const endpointFunc = DataModelService._PRODUCT_TYPES.find((p) => p.type === type);
        if (endpointFunc) {
            const endpoint = endpointFunc.endpoint(bearinxObjectTypes);
            return this._httpClient.get(`${this._baseUrl}${endpoint}`).pipe(
                catchError((error) => this._errorHandler.handleError(error)),
                map((items) => items || []),
                map((items) => items.sort(this._sortResult)),
                tap((items) => {
                    const language = this._translateService.currentLang;
                    // Adding translations, required for showing installation types and product features in the bearings database
                    items.forEach((item: { item: string; localization: { text: string } }) =>
                        this._translateService.set(item.item, item.localization.text, language),
                    );
                }),
            );
        }
        return this._dataModels.get(type);
    }

    private _onReceive(modelType: DataModelType, data: any): void {
        this._dataModels.set(modelType, data);
    }

    private _sortResult(model1: DataModel, model2: DataModel): number {
        const text1 = (model1 && model1.localization ? model1.localization.text : null) || '';
        const text2 = (model2 && model2.localization ? model2.localization.text : null) || '';

        return text1.localeCompare(text2);
    }
}
