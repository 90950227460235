import { Overlay } from '@angular/cdk/overlay';
import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    ElementRef,
    forwardRef,
    HostBinding,
    HostListener,
    Input,
    NgZone,
    OnChanges,
    SimpleChanges,
} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { Observable, of } from 'rxjs';
import { PanelControl } from '../panel-control';
import { MultiSelectOption } from './multi-select-option';

@Component({
    selector: 'bx-multi-select',
    templateUrl: 'multi-select.component.html',
    styleUrls: ['multi-select.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    // eslint-disable-next-line @typescript-eslint/no-use-before-define
    providers: [{ provide: NG_VALUE_ACCESSOR, useExisting: forwardRef(() => MultiSelectComponent), multi: true }],
})
export class MultiSelectComponent extends PanelControl implements ControlValueAccessor, OnChanges {
    @HostBinding('class.active')
    public isOpen = false;

    @HostBinding('class.disabled')
    public isDisabled = false;

    @Input()
    public options: MultiSelectOption[];

    @Input()
    public placeholder: string;

    @Input() public minWidth = 10;

    @Input() public maxWidth = 30;

    public selectedIds: any[] = [];

    private _onChange: (value: any[]) => void;
    private _onTouched: () => void;

    constructor(
        overlay: Overlay,
        elementRef: ElementRef,
        ngZone: NgZone,
        private readonly _changeDetectorRef: ChangeDetectorRef,
        private readonly _translateService: TranslateService,
    ) {
        super(overlay, ngZone, elementRef);
    }

    public ngOnChanges(changes: SimpleChanges): void {
        if (changes?.options?.currentValue != null) {
            this.calculateCustomeWidth(
                (changes!.options!.currentValue as MultiSelectOption[]).map(({ caption }) => caption),
                this.minWidth,
                this.maxWidth,
            );
        }
    }

    public get selected(): boolean {
        return !!this.selectedIds.length;
    }

    public get label$(): Observable<string> {
        const selected = (this.options || []).filter((item) => this.selectedIds.includes(item.id));

        if (selected.length === 0) {
            return of(this.placeholder);
        }

        if (selected.length === 1) {
            return of(selected[0].caption);
        }

        return this._translateService.get('SHARED.FORMS.MULTI_SELECT.ITEM_COUNT', { count: selected.length });
    }

    public get value(): any[] {
        return this.selectedIds;
    }

    public set value(value: any[]) {
        this.selectedIds = value;

        if (this._onChange) {
            this._onChange(this.selectedIds);
        }
        if (this._onTouched) {
            this._onTouched();
        }
        this._changeDetectorRef.markForCheck();
    }

    @HostListener('click')
    public attachOverlay(): void {
        if (this.isDisabled) {
            return;
        }

        this.toggle();
    }

    public updateValue(value: any[]): void {
        this.value = value || [];
    }

    public registerOnChange(fn: (value: any[]) => void): void {
        this._onChange = fn;
    }

    public registerOnTouched(fn: any): void {
        this._onTouched = fn;
    }

    public setDisabledState(isDisabled: boolean): void {
        this.isDisabled = isDisabled;
        this._changeDetectorRef.markForCheck();
    }

    public writeValue(value: any[] | null): void {
        if (!Array.isArray(value) && value !== null) {
            throw new Error('MultiSelectOption expects an Array or null.');
        }
        this.value = value || [];
    }
}
