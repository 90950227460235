import { Component, ElementRef, NgZone, OnDestroy, OnInit, Renderer2 } from '@angular/core';
import { ModalContext } from '../../models/modal.context';
import { fromEvent, Subject } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';
import { DialogButton, DialogButtonPosition } from '../../models/dialog.preset';

export const ANIMATION_CLASS = 'active';

@Component({
    selector: 'bx-modal',
    templateUrl: 'modal-container.component.html',
    styleUrls: ['modal-container.component.scss'],
})
export class ModalContainerComponent implements OnInit, OnDestroy {
    private _destroy = new Subject<void>();
    private _transitionEnd = fromEvent<TransitionEvent>(this.el.nativeElement, 'transitionend');
    public leftButtons: DialogButton[];
    public rightButtons: DialogButton[];

    public animationEnd$ = this._transitionEnd.pipe(
        filter((e) => e.target === this.el.nativeElement),
        takeUntil(this._destroy),
    );

    constructor(
        public context: ModalContext,
        public el: ElementRef,
        public renderer: Renderer2,
        private readonly _zone: NgZone,
    ) {
        this.context.container = this;
        this.leftButtons = this.context.buttons.filter((button) => button.position === DialogButtonPosition.Left);
        this.rightButtons = this.context.buttons.filter((button) => button.position === DialogButtonPosition.Right);
    }

    public buttonClicked(button: DialogButton, event: MouseEvent) {
        button.onClick(this, event);
    }

    public removeAnimationClass() {
        this.renderer.removeClass(this.el.nativeElement, ANIMATION_CLASS);
    }

    public ngOnInit(): void {
        this._zone.runOutsideAngular(() => setTimeout(() => this.renderer.addClass(this.el.nativeElement, ANIMATION_CLASS)));
    }

    public ngOnDestroy(): void {
        this._destroy.next();
    }
}
