import { HttpClient } from '@angular/common/http';
import { Injectable, OnDestroy } from '@angular/core';
import { combineLatest, ReplaySubject, Subject } from 'rxjs';
import { map, takeUntil } from 'rxjs/operators';
import { Version } from './version.model';
import { loadAppConfig } from '../util/util';
import { InitializationStatusService } from '../initialization/initialization-status.service';
import { TranslateService } from '@ngx-translate/core';
import { SettingsService } from '../settings/settings.service';

@Injectable({
    providedIn: 'root',
})
export class VersionService implements OnDestroy {
    public appConfig = loadAppConfig();

    private readonly _frontendVersion: string = require('../../../package.json').version;
    private readonly _baseUrl = `${this.appConfig.baseUrl}version`;
    private readonly _destroy$ = new Subject<void>();
    private readonly _versions$ = new ReplaySubject<Version[]>(1);
    private isInitializedSuccessfully = false;
    readonly versions$ = this._versions$.asObservable().pipe(
        map((versions) => [
            ...versions,
            {
                name: 'Frontend',
                version: this.getFrontendVersion(),
            },
        ]),
    );

    constructor(
        private readonly _httpClient: HttpClient,
        private readonly _initializationStatusService: InitializationStatusService,
        private readonly _translateService: TranslateService,
        private readonly _settingsService: SettingsService,
    ) {
        combineLatest([this._initializationStatusService.initializationStatus$, this._settingsService.currentLanguage$])
            .pipe(takeUntil(this._destroy$))
            .subscribe(([initStatus]) => {
                this.isInitializedSuccessfully = initStatus;
                if (this.isInitializedSuccessfully) {
                    this._getVersions();
                } else {
                    this._getVersionsNotAvailable();
                }
            });
    }

    public ngOnDestroy(): void {
        this._destroy$.next();
        this._destroy$.complete();
    }

    private _getVersions() {
        this._httpClient.get<Version[]>(this._baseUrl).pipe(takeUntil(this._destroy$)).subscribe(this._versions$);
    }

    private _getVersionsNotAvailable() {
        const versionsNotAvailable: Version[] = [
            {
                name: 'Bearinx',
                version: this._translateService.instant(`INITIALIZATION_FAILED.NOT_AVAILABLE`),
            },
            {
                name: 'Bearinx WebAPI',
                version: this._translateService.instant(`INITIALIZATION_FAILED.NOT_AVAILABLE`),
            },
        ];

        this._versions$.next(versionsNotAvailable);
    }

    public getFrontendVersion(): string {
        return this._frontendVersion;
    }
}
