import { Directive, ElementRef, Input, OnDestroy, OnInit, Renderer2 } from '@angular/core';
import { BehaviorSubject, combineLatest, ReplaySubject, Subscription } from 'rxjs';
import { RoleService } from '../role.service';
import { switchMap } from 'rxjs/operators';

@Directive({
    selector: '[bxDisabledUnlessRoles]',
})
export class DisabledUnlessRolesDirective implements OnInit, OnDestroy {
    private readonly _rolesSubject = new ReplaySubject<string[]>(1);
    private readonly _disabledSubject = new BehaviorSubject(false);
    private _subscription = Subscription.EMPTY;

    @Input()
    public set bxDisabledUnlessRoles(value: string[]) {
        this._rolesSubject.next(value);
    }

    @Input('disabled')
    public set disabled(value: boolean) {
        this._disabledSubject.next(value || false);
    }

    constructor(
        private readonly _roleService: RoleService,
        private readonly _elementRef: ElementRef,
        private readonly _renderer: Renderer2,
    ) {}

    public ngOnInit(): void {
        this._subscription = combineLatest([
            this._disabledSubject,
            this._rolesSubject.pipe(switchMap((roles) => this._roleService.userHasRoles(roles))),
        ]).subscribe(([disabled, userHasRoles]) => {
            if (disabled || !userHasRoles) {
                this._renderer.setAttribute(this._elementRef.nativeElement, 'disabled', '');
            } else if (!disabled && userHasRoles) {
                this._renderer.removeAttribute(this._elementRef.nativeElement, 'disabled');
            }
        });
    }

    public ngOnDestroy(): void {
        this._subscription.unsubscribe();
    }
}
