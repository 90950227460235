import { Pipe, PipeTransform } from '@angular/core';
import { IconContext, IconMappingService } from './icon-mapping/icon-mapping.service';

@Pipe({
    name: 'icon',
})
export class IconPipe implements PipeTransform {
    constructor(private readonly _iconMappingService: IconMappingService) {}

    transform(type: string, context: IconContext): string | undefined {
        return this._iconMappingService.getIcon([type], context);
    }
}
