/**
 * Checks if the given object owns a property with the given path in dot notation
 * @param object Object to be checked
 * @param propertyPath Path to be check in dot notation, e.g. 'abc' or 'abc.xyz'
 * @returns True if found, otherwise false
 */
export function hasProperty(object: any, propertyPath: string[] | string): boolean {
    let result = false;
    if (object) {
        object = typeof object === 'object' ? object : {};

        const keys = Array.isArray(propertyPath) ? propertyPath : propertyPath.split('.');

        let currentObject = object;
        result = keys.every((key) => {
            let hasProperty = false;
            if (currentObject && currentObject.hasOwnProperty(key)) {
                currentObject = currentObject[key];
                hasProperty = true;
            }
            return hasProperty;
        });
    }
    return result;
}
