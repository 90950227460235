import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'sortBy' })
export class SortPipe implements PipeTransform {
    public transform(value: Array<any> | null, propertyName: string): Array<any> {
        if (!value) {
            return [];
        }
        if (!propertyName) {
            return value;
        }

        if (value.length > 0 && !value[0].hasOwnProperty(propertyName)) {
            return value;
        }
        value.sort((a: any, b: any) => {
            if (a[propertyName] < b[propertyName]) {
                return -1;
            } else if (a[propertyName] > b[propertyName]) {
                return 1;
            } else {
                return 0;
            }
        });
        return value;
    }
}
