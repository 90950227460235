<form [formGroup]="form">
    <bx-form-field>
        <bx-checkbox formControlName="delLoadCases" (change)="save()" [label]="'SHARED.SETTINGS.WARNING.CASES' | translate"></bx-checkbox>
    </bx-form-field>

    <bx-form-field>
        <bx-checkbox formControlName="delShafts" (change)="save()" [label]="'SHARED.SETTINGS.WARNING.SHAFTS' | translate"></bx-checkbox>
    </bx-form-field>

    <bx-form-field>
        <bx-checkbox formControlName="delSystems" (change)="save()" [label]="'SHARED.SETTINGS.WARNING.SYSTEMS' | translate"></bx-checkbox>
    </bx-form-field>

    <bx-form-field>
        <bx-checkbox formControlName="delLinAxes" (change)="save()" [label]="'SHARED.SETTINGS.WARNING.LIN_AXES' | translate"></bx-checkbox>
    </bx-form-field>
</form>
